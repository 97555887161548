import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-normalwins',
  templateUrl: './normalwins.component.html',
  styleUrls: ['./normalwins.component.scss'],
})
export class normalwinsComponent implements OnInit {
  tax: number;
  FullPrice: number;
  constructor(public router:Router){}
  SelectedAccount: string = '';
  TotalPrice: number = 0;
  SelectedGames: number = 1;
  ImageSrc :string ='./assets/questionmark.png';
  ngOnInit() {
    this.NormalWinsCalculate(1);
  }
  // AccountBuy(event: any) {
  //   this.SelectedAccount = event;
  //   if (event == 'Herald 1 (MMR 10)') {
  //     this.ImageSrc = './assets/icons/1.jpg';
  //     this.TotalPrice = 95;
  //   }
  //   if (event == 'Herald 2 (MMR 150)') {
  //     this.ImageSrc = './assets/icons/2.jpg';
  //     this.TotalPrice = 85;
  //   }
  //   if (event == 'Herald 3 (MMR 300)') {
  //     this.ImageSrc = './assets/icons/3.jpg';
  //     this.TotalPrice = 80;
  //   }
  //   if (event == 'Herald 4 (MMR 450)') {
  //     this.ImageSrc = './assets/icons/4.jpg';
  //     this.TotalPrice = 75;
  //   }
  //   if (event == 'Herald 5 (MMR 600)') {
  //     this.ImageSrc = './assets/icons/5.jpg';
  //     this.TotalPrice = 70; 
  //   }
  //   if (event == 'Guardian 1(MMR 740)') {
  //     this.ImageSrc = './assets/icons/6.jpg';
  //     this.TotalPrice = 50;
  //   }
  //   if (event == 'Guardian 2(MMR 900)') {
  //     this.ImageSrc = './assets/icons/7.jpg';
  //     this.TotalPrice = 45;
  //   }
  //   if (event == 'Guardian 3 (MMR 1100)') {
  //     this.ImageSrc = './assets/icons/8.jpg';
  //     this.TotalPrice = 43;
  //   }
  //   if (event == 'Guardian 4 (MMR 1240)') {
  //     this.ImageSrc = './assets/icons/9.jpg';
  //     this.TotalPrice = 44;
  //   }
  //   if (event == 'Guardian 5 (MMR 1440)') {
  //     this.ImageSrc = './assets/icons/10.jpg';
  //     this.TotalPrice = 46;
  //   }
  //   if (event == 'Crusader 1 (MMR 1600)') {
  //     this.ImageSrc = './assets/icons/11.jpg';
  //     this.TotalPrice = 32;
  //   }
  //   if (event == 'Crusader 2 (MMR 1740)') {
  //     this.ImageSrc = './assets/icons/12.jpg';
  //     this.TotalPrice = 34;
  //   }
  //   if (event == 'Crusader 3 (MMR 1900)') {
  //     this.ImageSrc = './assets/icons/13.jpg';
  //     this.TotalPrice = 36;
  //   }
  //   if (event == 'Crusader 4 (MMR 2100)') {
  //     this.ImageSrc = './assets/icons/14.jpg';
  //     this.TotalPrice = 35;
  //   }
  //   if (event == 'Crusader 5 (MMR 2340)') {
  //     this.ImageSrc = './assets/icons/15.jpg';
  //     this.TotalPrice = 37;
  //   }
  //   if (event == 'Archon 1 (MMR 2360)') {
  //     this.ImageSrc = './assets/icons/16.jpg';
  //     this.TotalPrice = 39;
  //   }
  //   if (event == 'Archon 2 (MMR 2580)') {
  //     this.ImageSrc = './assets/icons/17.jpg';
  //     this.TotalPrice = 40;
  //   }
  //   if (event == 'Archon 3 (MMR 2740)') {
  //     this.ImageSrc = './assets/icons/18.jpg';
  //     this.TotalPrice = 41;
  //   }
  //   if (event == 'Archon 4 (MMR 2900)') {
  //     this.ImageSrc = './assets/icons/19.jpg';
  //     this.TotalPrice = 42;
  //   }
  //   if (event == 'Archon 5 (MMR 3040)') {
  //     this.ImageSrc = './assets/icons/20.jpg';
  //     this.TotalPrice = 43;
  //   }
  //   if (event == 'Legend 1 (MMR 3080)') {
  //     this.ImageSrc = './assets/icons/21.jpg';
  //     this.TotalPrice = 44;
  //   }
  //   if (event == 'Legend 2 (MMR 3200)') {
  //     this.ImageSrc = './assets/icons/22.jpg';
  //     this.TotalPrice = 46;
  //   }
  //   if (event == 'Legend 3 (MMR 3360)') {
  //     this.ImageSrc = './assets/icons/23.jpg';
  //     this.TotalPrice = 47;
  //   }
  //   if (event == 'Legend 4 (MMR 3560)') {
  //     this.ImageSrc = './assets/icons/24.jpg';
  //     this.TotalPrice = 48;
  //   }
  //   if (event == 'Legend 5 (MMR 3760)') {
  //     this.ImageSrc = './assets/icons/25.jpg';
  //     this.TotalPrice = 52;
  //   }
  //   if (event == 'Ancient 1 (MMR 3850)') {
  //     this.ImageSrc = './assets/icons/26.png';
  //     this.TotalPrice = 55;
  //   }
  //   if (event == 'Ancient 2 (MMR 3990)') {
  //     this.ImageSrc = './assets/icons/27.jpg';
  //     this.TotalPrice = 56;
  //   }
  //   if (event == 'Ancient 3 (MMR 4130)') {
  //     this.ImageSrc = './assets/icons/28.jpg';
  //     this.TotalPrice = 59;
  //   }
  //   if (event == 'Ancient 4 (MMR 4310)') {
  //     this.ImageSrc = './assets/icons/29.jpg';
  //     this.TotalPrice = 62;
  //   }
  //   if (event == 'Ancient 5 (MMR 4480)') {
  //     this.ImageSrc = './assets/icons/30.jpg';
  //     this.TotalPrice = 66;
  //   }
  //   if (event == 'Divine 1 (MMR 4640)') {
  //     this.ImageSrc = './assets/icons/31.jpg';
  //     this.TotalPrice = 68;
  //   }
  //   if (event == 'Divine 2 (MMR 4790)') {
  //     this.ImageSrc = './assets/icons/32.jpg';
  //     this.TotalPrice = 70;
  //   }
  //   if (event == 'Divine 3 (MMR 4930)') {
  //     this.ImageSrc = './assets/icons/33.jpg';
  //     this.TotalPrice = 90;
  //   }
  //   if (event == 'Divine 4 (MMR 5130)') {
  //     this.ImageSrc = './assets/icons/34.jpg';
  //     this.TotalPrice = 105;
  //   }
  //   if (event == 'Divine 5 (MMR 5380)') {
  //     this.ImageSrc = './assets/icons/35.jpg';
  //     this.TotalPrice = 120;
  //   }
  //   if (event == 'Immortal (MMR 5640)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 150;
  //   }
  //   if (event == 'Immortal (MMR 6000)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 170;
  //   }
  //   if (event == 'Immortal (MMR 6300)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 220;
  //   }
  //   if (event == 'Immortal (MMR 6500)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 240;
  //   }
  //   if (event == 'Immortal (MMR 6800)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 330;
  //   }
  //   if (event == 'Immortal (MMR 7000)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 380;
  //   }
  //   if (event == 'Immortal (MMR 7200)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 410;
  //   }
  //   if (event == 'Immortal (MMR 7500)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 480;
  //   }
  //   if (event == 'Immortal (MMR 8000)') {
  //     this.ImageSrc = './assets/icons/immortal.jpg';
  //     this.TotalPrice = 750;
  //   }
  //   if(event =='')
  //   {
  //     this.ImageSrc ='./assets/questionmark.png';
  //     this.TotalPrice = 0; 
  //   }
  // }

  NormalWinsCalculate(num:number){
    this.SelectedGames = num
    this.TotalPrice = num *3;
    this.tax = (this.TotalPrice / 100) * 4;
    this.FullPrice = this.TotalPrice + this.tax;
  }

  GoToCheckout(){
    localStorage.setItem('ServiceName','Normal Wins');
    localStorage.setItem('StartFrom','');
    localStorage.setItem('ExpectedDays','0');
    localStorage.setItem('Desired','Selected Number of Games : '+this.SelectedGames.toString());
    localStorage.setItem('Streaming','');
    localStorage.setItem('DuoQueue','');
    localStorage.setItem('SpecificHero','');
    localStorage.setItem('PriorityCompletion','');
    localStorage.setItem('PlayOffline','');
    localStorage.setItem('ServicePrice',this.TotalPrice.toString());
    localStorage.setItem('SubTotal',this.TotalPrice.toString());
    localStorage.setItem('TaxPrice',this.tax.toString());
    localStorage.setItem('TotalPrice',this.FullPrice.toString());
    localStorage.setItem('CustomDesiredLabel','true');

    window.open("https://dota2mmrboost.com/checkout", "_blank");

    //this.router.navigateByUrl('/Checkout');
  }


}
