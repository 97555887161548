import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rankcalibration',
  templateUrl: './rankcalibration.component.html',
  styleUrls: ['./rankcalibration.component.scss'],
})
export class RankCalibrationComponent {

  constructor(public router:Router){}
  IsDuoQueueChecked: boolean = false;
  DuoQueuePercentage: number = 80;
  StreamingPercentage: number = 15;
  HeroPrefrencePercentage: number = 15;
  PriorityPercentage: number = 20;

  value: number = 1;
  minvalue: number = 0;

  IsStreamingChecked: boolean = false;
  IsPriorityCompletionChecked: boolean = false;
  IsHeroPreferencesChecked: boolean = false;
  IsPlayOfflineChecked: boolean = false;
  imagePath = '';
  CurrentRank: string = '';
  DuoQueue: string;
  Streaming: string;
  HeroPreferences: string;
  PriorityCompletion: string;
  PlayOffline: string;
  tax: number;
  FullPrice: number;

  min100: number = 100;
  TotalPrice: number = 3.5;
  currrentPrice: number = 3.5;
  couponName: string = 'ECHO10SLAM';
  actualPrice: number;
  couponList: any;
  FirstTimeInit: boolean = false;
  // value: number = 123;
  options: Options = {
    showTicksValues: true,

    tickStep: 1,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 },
      { value: 12 },
      { value: 13 },
      { value: 14 },
      { value: 15 },
    ],
  };
  hideActualPrice: boolean = false;
  updatedPrice: number;
  AfterDuoChecked: boolean = false;
  updatedMinValue: number = 0;
  ngOnInit() {
    this.couponList = new Array();
    this.actualPrice = this.TotalPrice;
    this.couponList = [{ Name: 'ECHO10SLAM', Value: 10 }];
    this.genericPriceFunction(this.value);
  }
  genericPriceFunction(value: any) {
    this.TotalPrice = value * this.currrentPrice;
    this.actualPrice = this.TotalPrice;
    var currentCouponList = this.couponList.find(
      (x) => x.Name == this.couponName
    );
    if (currentCouponList != null) {
      if (currentCouponList != undefined) {
        this.TotalPrice =
          this.TotalPrice - this.TotalPrice * (currentCouponList.Value / 100);
        this.hideActualPrice = true;
      }
    } else {
      this.TotalPrice = this.actualPrice;
      this.hideActualPrice = false;
    }
    this.CheckIfDuoChecked();
    this.CheckIfStreamingChecked();
    this.CheckIfPriorityChecked();
    this.CheckIfHeroPrefrenceChecked();
    if (this.updatedMinValue == 0) {
      this.CurrentRank = 'Unranked';
    }
    if (this.updatedMinValue == 1) {
      this.CurrentRank = 'Herald';
    }
    if (this.updatedMinValue == 2) {
      this.CurrentRank = 'Guardian';
    }
    if (this.updatedMinValue == 3) {
      this.CurrentRank = 'Crusader';
    }
    if (this.updatedMinValue == 4) {
      this.CurrentRank = 'Archon';
    }
    if (this.updatedMinValue == 5) {
      this.CurrentRank = 'Legend';
    }
    if (this.updatedMinValue == 6) {
      this.CurrentRank = 'Ancient';
    }
    if (this.updatedMinValue == 7) {
      this.CurrentRank = 'Divine';
    }
    if (this.updatedMinValue == 8) {
      this.CurrentRank = 'Immortal';
    }

    if (this.IsDuoQueueChecked == true) {
      this.DuoQueue = 'Yes';
    } else {
      this.DuoQueue = 'No';
    }
    if (this.IsHeroPreferencesChecked == true) {
      this.HeroPreferences = 'Yes';
    } else {
      this.HeroPreferences = 'No';
    }
    if (this.IsStreamingChecked == true) {
      this.Streaming = 'Yes';
    } else {
      this.Streaming = 'No';
    }
    if (this.IsPriorityCompletionChecked == true) {
      this.PriorityCompletion = 'Yes';
    } else {
      this.PriorityCompletion = 'No';
    }
    if (this.IsPlayOfflineChecked == true) {
      this.PlayOffline = 'Yes';
    } else {
      this.PlayOffline = 'No';
    }
    var a = parseFloat(this.TotalPrice.toString()).toFixed(2);
    this.TotalPrice = parseInt(a);
    this.tax = (this.TotalPrice / 100) * 4;
    this.FullPrice = this.TotalPrice + this.tax;
  }
  setUnitPrice(value: any) {
    if (value == '0' || value == '1') {
      this.currrentPrice = 3.5;
      this.TotalPrice = 3.5;
    }
    if (value == '2') {
      this.currrentPrice = 3.7;
      this.TotalPrice = 3.7;
    }
    if (value == '3') {
      this.currrentPrice = 4;
      this.TotalPrice = 4;
    }
    if (value == '4') {
      this.currrentPrice = 4.5;
      this.TotalPrice = 4.5;
    }
    if (value == '5') {
      this.currrentPrice = 5;
      this.TotalPrice = 5;
    }
    if (value == '6') {
      this.currrentPrice = 5.6;
      this.TotalPrice = 5.6;
    }
    if (value == '7') {
      this.currrentPrice = 5.9;
      this.TotalPrice = 5.9;
    }
    if (value == '8') {
      this.currrentPrice = 6.2;
      this.TotalPrice = 6.2;
    }
  }
  CheckIfDuoChecked() {
    if (this.IsDuoQueueChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.DuoQueuePercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.DuoQueuePercentage / 100);
    }
  }
  CheckIfStreamingChecked() {
    if (this.IsStreamingChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.StreamingPercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.StreamingPercentage / 100);
    }
  }

  CheckIfHeroPrefrenceChecked() {
    if (this.IsHeroPreferencesChecked == true) {
      this.TotalPrice =
        this.TotalPrice +
        this.TotalPrice * (this.HeroPrefrencePercentage / 100);
      this.actualPrice =
        this.actualPrice +
        this.actualPrice * (this.HeroPrefrencePercentage / 100);
    }
  }
  CheckIfPriorityChecked() {
    if (this.IsPriorityCompletionChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.PriorityPercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.PriorityPercentage / 100);
    }
  }
  onUserChange(event: any): void {
    // this.value = event.value;
    this.genericPriceFunction(this.value);
  }

  onKey(event: any) {
    // without type info
    this.couponName = event.target.value;
    this.genericPriceFunction(this.value);
  }

  CalculateFinalResult(min: any, max: any) {
    const stepSize = 1000;
    const absoluteMin = 0;
    const absoluteMax = 10000;
    const totalSteps = absoluteMax / stepSize;
    let sum = 0;
    let minFound = false;
    let maxFound = false;
    for (var multiplier = 1; multiplier <= totalSteps; multiplier++) {
      let end = multiplier * stepSize;
      let start = end - stepSize;
      if (min >= start && max < end) {
        sum = (max - min) * multiplier;
        break;
      }
      if (min > end) {
        continue;
      }
      if (min >= start && min <= end) {
        sum += (end - min) * multiplier;
        console.log('min found', sum);
        continue;
      }
      if (max >= start && max <= end) {
        sum += (end - max) * multiplier;
        break;
      }
      sum += end * multiplier;
    }
    return sum;
  }
  MinsValueButton() {
    if (this.value > 1) {
      this.value -= 1;
      this.genericPriceFunction(this.value);
    }
  }
  PlusValueButton() {
    if (this.value < 10) {
      this.value += 1;
      this.genericPriceFunction(this.value);
    }
  }

  onKeyMin(value: number) {
    if (this.value > 1) {
      this.value -= 1;
    }
  }
  onKeyMax(value: number) {
    if (this.value <= 10) {
      this.value += 1;
      // this.genericPriceFunction(this.value);
    }
  }

  setMinsValueButton() {
    if (this.minvalue > 1) {
      this.minvalue -= 1;
      this.setUnitPrice(this.minvalue);
      this.genericPriceFunction(this.value);
    }
  }
  setPlusValueButton() {
    if (this.minvalue < 9) {
      this.minvalue += 1;
      this.setUnitPrice(this.minvalue);
      this.genericPriceFunction(this.value);
    }
  }
  setonKeyMin(value: number) {
    this.updatedMinValue = value;
    this.setUnitPrice(value);
    this.genericPriceFunction(this.value);
  }
  setonKeyMax(value: number) {
    this.setUnitPrice(this.minvalue);
    // this.genericPriceFunction(this.value);
  }

  // updatedPrice: number;
  onDuoChange(event: any) {
    this.IsDuoQueueChecked = event;
    this.genericPriceFunction(this.value);
  }
  onStreamingChange(event: any) {
    this.IsStreamingChecked = event;
    this.genericPriceFunction(this.value);
  }
  onHeroPrefrenceChange(event: any) {
    this.IsHeroPreferencesChecked = event;
    this.genericPriceFunction(this.value);
  }

  onPriorityChange(event: any) {
    this.IsPriorityCompletionChecked = event;
    this.genericPriceFunction(this.value);
  }

  onIsPlayOfflineCheckedChange(event: any) {
    this.IsPlayOfflineChecked = event;
    this.genericPriceFunction(this.value);
  }
  GoToCheckout(){
    localStorage.setItem('ServiceName','Rank Calibration');
    localStorage.setItem('ServicePrice',this.TotalPrice.toString());
    localStorage.setItem('SubTotal',this.TotalPrice.toString());
    localStorage.setItem('TaxPrice',this.tax.toString());
    localStorage.setItem('TotalPrice',this.FullPrice.toString());
    localStorage.setItem('DuoQueue',this.IsDuoQueueChecked == true?'Yes':'No');
    localStorage.setItem('StartFrom','Current Rank : '+this.CurrentRank.toString());
    localStorage.setItem('Desired','Calibration Matches : '+this.value.toString());
    localStorage.setItem('Streaming',this.IsStreamingChecked == true?'Yes':'No');
    localStorage.setItem('SpecificHero',this.IsHeroPreferencesChecked == true?'Yes':'No');
    localStorage.setItem('PriorityCompletion',this.IsPriorityCompletionChecked == true?'Yes':'No');
    localStorage.setItem('PlayOffline',this.IsPlayOfflineChecked == true?'Yes':'No');
    localStorage.setItem('ExpectedDays','2');
    localStorage.setItem('CustomDesiredLabel','true');

     window.open("https://dota2mmrboost.com/checkout", "_blank");

  
    // this.router.navigateByUrl('/Checkout');
  }
}
