import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dota2HeroTierBoost',
  templateUrl: './dota2HeroTierBoost.component.html',
  styleUrls: ['./dota2HeroTierBoost.component.scss'],
})
export class dota2HeroTierBoostComponent {
  constructor(public router: Router) {}
  StreamingPercentage: number = 15;
  PriorityPercentage: number = 20;
  currentTier: number = 0;
  desiredTier: number = 1;
  DuoQueue: string;
  Streaming: string;
  HeroPreferences: string;
  PriorityCompletion: string;
  PlayOffline: string;
  tax: number = 0;
  FullPrice: number = 0;
  HeroName : string= '';
  IsStreamingChecked: boolean = false;
  IsPriorityCompletionChecked: boolean = false;
  IsHeroPreferencesChecked: boolean = false;
  IsPlayOfflineChecked: boolean = false;
  imagePath = '';

  TotalPrice: number = 0;
  currrentPrice: number = 0;
  actualPrice: number;

  hideActualPrice: boolean = false;
  updatedPrice: number;
  AfterDuoChecked: boolean = false;
  ngOnInit() {
    this.ChangeMedal();
  }

  CheckIfStreamingChecked() {
    if (this.IsStreamingChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.StreamingPercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.StreamingPercentage / 100);
    }
  }

  CheckIfPriorityChecked() {
    if (this.IsPriorityCompletionChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.PriorityPercentage / 100);
    }
  }

  onStreamingChange(event: any) {
    this.IsStreamingChecked = event;
    this.ChangeMedal();

    //this.genericPriceFunction(this.value);
  }

  onPriorityChange(event: any) {
    this.IsPriorityCompletionChecked = event;
    this.ChangeMedal();
    //this.genericPriceFunction(this.value);
  }

  onIsPlayOfflineCheckedChange(event: any) {
    this.IsPlayOfflineChecked = event;
    //this.genericPriceFunction(this.value);
  }
  GoToCheckout() {
    debugger;
    var currentTiername = this.medalName(this.currentTier);
    var desiredTiername = this.medalName(this.desiredTier);
    localStorage.setItem('ServiceName', 'Dota2 Hero Tier Boost');
    localStorage.setItem('StartFrom', 'Current Tier : ' + currentTiername);
    localStorage.setItem('ExpectedDays', '1');
    localStorage.setItem('Desired', 'Desired Tier : ' + desiredTiername);
    localStorage.setItem(
      'Streaming',
      this.IsStreamingChecked == true ? 'Yes' : 'No'
    );
    localStorage.setItem('DuoQueue','Hero Name : ' +this.HeroName);
    localStorage.setItem('SpecificHero', '');
    localStorage.setItem(
      'PriorityCompletion',
      this.IsPriorityCompletionChecked == true ? 'Yes' : 'No'
    );
    localStorage.setItem('ServicePrice', this.TotalPrice.toString());
    localStorage.setItem('SubTotal', this.TotalPrice.toString());
    localStorage.setItem('TaxPrice', this.tax.toString());
    localStorage.setItem('TotalPrice', this.FullPrice.toString());
    localStorage.setItem(
      'PlayOffline',
      this.IsPlayOfflineChecked == true ? 'Yes' : 'No'
    );
    localStorage.setItem('CustomDesiredLabel', 'true');

    window.open('https://dota2mmrboost.com/checkout', '_blank');

    //this.router.navigateByUrl('/Checkout');
  }

  ChangeMedal() {
    this.TotalPrice = (this.desiredTier - this.currentTier) * 20;
    this.CheckIfPriorityChecked();
    this.CheckIfStreamingChecked();
    this.tax = (this.TotalPrice / 100) * 4;
    this.FullPrice = this.TotalPrice + this.tax;
  }

  medalName(value: number) : string {
    if (value == 0) return 'No Tier - Level';
    if (value == 1) return 'Bronze - Level 1';
    if (value == 2) return 'Bronze - Level 2';

    if (value == 3) return 'Bronze - Level 3';

    if (value == 4) return 'Bronze - Level 4';

    if (value == 5) return 'Bronze - Level 5';

    if (value == 6) return 'Silver - Level 6';

    if (value == 7) return 'Silver - Level 7';

    if (value == 8) return 'Silver - Level 8';

    if (value == 9) return 'Silver - Level 9';

    if (value == 10) return 'Silver - Level 10';

    if (value == 11) return 'Silver - Level 11';

    if (value == 12) return 'Gold - Level 12';

    if (value == 13) return 'Gold - Level 13';

    if (value == 14) return 'Gold - Level 14';

    if (value == 15) return 'Gold - Level 15';

    if (value == 16) return 'Gold - Level 16';

    if (value == 17) return 'Gold - Level 17';

    if (value == 18) return 'Platinum - Level 18';

    if (value == 19) return 'Platinum - Level 19';

    if (value == 20) return 'Platinum - Level 20';

    if (value == 21) return 'Platinum - Level 21';

    if (value == 22) return 'Platinum - Level 22';

    if (value == 23) return 'Platinum - Level 23';

    if (value == 24) return 'Platinum - Level 24';

    if (value == 25) return 'Master - Level 25';

    if (value == 26) return 'Master - Level 26';

    if (value == 27) return 'Master - Level 27';

    if (value == 28) return 'Master - Level 28';

    if (value == 29) return 'Master - Level 29';

    if (value == 30) return 'Grand Master - Level 30';
  }
}
