<form #demo>
  <div class="separator">
    <div class="line"></div>
    <p class="h3ReplaceHeading orderText">ORDER</p>
    <div class="line"></div>
  </div>
  <div class="row m-2">
    <div class="col-md-6 text-center">
      <img
      class="ImageSizeSetting"
      [src]="ImageSrc"
    />
    </div>
    <div class="col-md-6 p-5 text-center">
     
      <select name="" id="" #Accountdetails class="input-min" (change)="AccountBuy(Accountdetails.value)" [value]="SelectedAccount">
        <option value="">Choose an option</option>
        <option value="Herald 1 (MMR 10)">Herald 1 (MMR 10)</option>
        <option value="Herald 2 (MMR 150)">Herald 2 (MMR 150)</option>
        <option value="Herald 3 (MMR 300)">Herald 3 (MMR 300)</option>
        <option value="Herald 4 (MMR 450)">Herald 4 (MMR 450)</option>
        <option value="Herald 5 (MMR 600)">Herald 5 (MMR 600)</option>
        <option value="Guardian 1(MMR 740)">Guardian 1(MMR 740)</option>
        <option value="Guardian 2(MMR 900)">Guardian 2(MMR 900)</option>
        <option value="Guardian 3 (MMR 1100)">Guardian 3 (MMR 1100)</option>
        <option value="Guardian 4 (MMR 1240)">Guardian 4 (MMR 1240)</option>
        <option value="Guardian 5 (MMR 1440)">Guardian 5 (MMR 1440)</option>
        <option value="Crusader 1 (MMR 1600)">Crusader 1 (MMR 1600)</option>
        <option value="Crusader 2 (MMR 1740)">Crusader 2 (MMR 1740)</option>
        <option value="Crusader 3 (MMR 1900)">Crusader 3 (MMR 1900)</option>
        <option value="Crusader 4 (MMR 2100)">Crusader 4 (MMR 2100)</option>
        <option value="Crusader 5 (MMR 2340)">Crusader 5 (MMR 2340)</option>
        <option value="Archon 1 (MMR 2360)">Archon 1 (MMR 2360)</option>
        <option value="Archon 2 (MMR 2580)">Archon 2 (MMR 2580)</option>
        <option value="Archon 3 (MMR 2740)">Archon 3 (MMR 2740)</option>
        <option value="Archon 4 (MMR 2900)">Archon 4 (MMR 2900)</option>
        <option value="Archon 5 (MMR 3040)">Archon 5 (MMR 3040)</option>
        <option value="Legend 1 (MMR 3080)">Legend 1 (MMR 3080)</option>
        <option value="Legend 2 (MMR 3200)">Legend 2 (MMR 3200 )</option>
        <option value="Legend 3 (MMR 3360)">Legend 3 (MMR 3360 )</option>
        <option value="Legend 4 (MMR 3560)">Legend 4 (MMR 3560)</option>
        <option value="Legend 5 (MMR 3760)">Legend 5 (MMR 3760)</option>
        <option value="Ancient 1 (MMR 3850)">Ancient 1 (MMR 3850)</option>
        <option value="Ancient 2 (MMR 3990)">Ancient 2 (MMR 3990)</option>
        <option value="Ancient 3 (MMR 4130)">Ancient 3 (MMR 4130)</option>
        <option value="Ancient 4 (MMR 4310)">Ancient 4 (MMR 4310)</option>
        <option value="Ancient 5 (MMR 4480)">Ancient 5 (MMR 4480)</option>
        <option value="Divine 1 (MMR 4640)">Divine 1 (MMR 4640)</option>
        <option value="Divine 2 (MMR 4790)">Divine 2 (MMR 4790)</option>
        <option value="Divine 3 (MMR 4930)">Divine 3 (MMR 4930)</option>
        <option value="Divine 4 (MMR 5130)">Divine 4 (MMR 5130)</option>
        <option value="Divine 5 (MMR 5380)">Divine 5 (MMR 5380)</option>
        <option value="Immortal (MMR 5640)">Immortal (MMR 5640)</option>
        <option value="Immortal (MMR 6000)">Immortal (MMR 6000)</option>
        <option value="Immortal (MMR 6300)">Immortal (MMR 6300)</option>
        <option value="Immortal (MMR 6500)">Immortal (MMR 6500)</option>
        <option value="Immortal (MMR 6800)">Immortal (MMR 6800)</option>
        <option value="Immortal (MMR 7000)">Immortal (MMR 7000)</option>
        <option value="Immortal (MMR 7200)">Immortal (MMR 7200)</option>
        <option value="Immortal (MMR 7500)">Immortal (MMR 7500)</option>
        <option value="Immortal (MMR 8000)">Immortal (MMR 8000)</option>
      </select>
      <p *ngIf="TotalPrice != 0" class="text-white m-4" style="font-size: 26px;"> 
        ${{TotalPrice | number : '1.2-2'}}</p>
        <div *ngIf="TotalPrice != 0">
         
          <!-- <app-Paypal  
          [ServiceName]="'Account Buy'"
          [StartFrom]="''"
          [ExpectedDays]="0"
          [Desired]="SelectedAccount"
          [Streaming]="''"
          [SpecificHero]="''"
          [PriorityCompletion]="''"
          [PlayOffline]="''"
          [ServicePrice]="TotalPrice"
          [SubTotal]="TotalPrice"
          [TaxPrice]="0"
          [DuoQueue]="''"
          [TotalPrice]="TotalPrice"></app-Paypal>
          <img class="mb-3" src="../../assets/paypalImages.png" width="70%" /> -->
          <button width="70%" type="button" class="btn btn-primary mt-3 mb-2" style="width: 80%;" (click)="GoToCheckout()">Checkout</button>
        </div>

      

    </div>
  </div>
</form>
