import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeService } from '../services/stripe.service';

declare const $: any;
declare let Email: any;
@Component({
  selector: 'app-Checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class checkoutComponent implements OnInit, OnDestroy {
  paymentForm: FormGroup;
  CustomerEmail: string='';
  ShowRadioButtons: boolean = false;
  UserCountry: any;
  UserEmail: any;
  UserDiscord: any;
  UserOrderNote: any;
 
  get validForm() {
    return this.paymentForm.valid;
  }

  validFields(){
    debugger;
    this.UserFirstName = this.paymentForm.value.name;
    this.UserCountry = this.paymentForm.value.country;
    this.UserEmail = this.paymentForm.value.email;
    this.UserDiscord = this.paymentForm.value.discord;
    this.UserOrderNote = this.paymentForm.value.orderNote;
   if(this.validForm)
   {
    this.ShowRadioButtons = true;
   }
  }
  
  @Input() ServiceName: string = 'Service Name';
  @Input() StartFrom: string = '0';
  @Input() DuoQueue: string = '0';
  @Input() ExpectedDays: number = 0;
  @Input() Desired: string = '0';
  @Input() Streaming: string = '0';
  @Input() SpecificHero: string = '0';
  @Input() PriorityCompletion: string = '0';
  @Input() PlayOffline: string = '0';
  @Input() ServicePrice: string = '0';
  @Input() SubTotal: string = '0';
  @Input() TaxPrice: string = '0';
  @Input() TotalPrice: string = '0';
  focus;
  focus1;
  focus2;
  Result: any;
  GateWayButton: string = 'Stripe';
  OrderId: any;
  CustomDesiredLabel: boolean = false;
  disableRadioButton: boolean = true;
  UserFirstName: string = 'Stripe';
  constructor(
    public httpClient: HttpClient,
    private fb: FormBuilder,
    public stripeService: StripeService
  ) {}
  ngOnDestroy(): void {
    localStorage.removeItem('ServiceName');
    localStorage.removeItem('ServicePrice');
    localStorage.removeItem('SubTotal');
    localStorage.removeItem('TaxPrice');
    localStorage.removeItem('TotalPrice');
    localStorage.removeItem('DuoQueue');
    localStorage.removeItem('StartFrom');
    localStorage.removeItem('Desired');
    localStorage.removeItem('Streaming');
    localStorage.removeItem('SpecificHero');
    localStorage.removeItem('PriorityCompletion');
    localStorage.removeItem('PlayOffline');
    localStorage.removeItem('ExpectedDays');
    localStorage.removeItem('CustomDesiredLabel');
  }
  ngOnInit(): void {
    this.invokeStripe();
    this.ServiceName = localStorage.getItem('ServiceName');
    this.ServicePrice = localStorage.getItem('ServicePrice');
    this.SubTotal = localStorage.getItem('ServicePrice');
    this.TaxPrice = localStorage.getItem('TaxPrice');
    this.TotalPrice = localStorage.getItem('TotalPrice');
    this.DuoQueue = localStorage.getItem('DuoQueue');
    this.StartFrom = localStorage.getItem('StartFrom');
    this.Desired = localStorage.getItem('Desired');
    this.Streaming = localStorage.getItem('Streaming');
    this.SpecificHero = localStorage.getItem('SpecificHero');
    this.PriorityCompletion = localStorage.getItem('PriorityCompletion');
    this.PlayOffline = localStorage.getItem('PlayOffline');
    this.ExpectedDays = parseInt(localStorage.getItem('ExpectedDays'));
    if (localStorage.getItem('CustomDesiredLabel') == 'true') {
      this.CustomDesiredLabel = true;
    } else {
      this.CustomDesiredLabel = false;
    }

    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]],
      country: ['', [Validators.required]],
      email: ['', [Validators.required]],
      discord: ['', []],
      orderNote: ['', []],
    });
    this.initConfig();
    //throw new Error('Method not implemented.');
  }
 

  // buy() {
  //   debugger;
  //   this.OrderId = ''; //+this.Result.id;
  //   this.httpClient
  //     .get('assets/emailTemplaate.txt', { responseType: 'text' })
  //     .subscribe((data1) => {
  //       this.emailTemplate = data1;
  //       var todayDate = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[OrderNumber]]]',
  //         this.OrderId
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[OrderNumber]]]',
  //         this.OrderId
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[OrderNumber]]]',
  //         this.OrderId
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[TodayDate]]]',
  //         todayDate
  //       );
  //       var ExpectedDate = formatDate(
  //         new Date().setDate(new Date().getDate() + this.ExpectedDays),
  //         'dd.MM.yyyy',
  //         'en'
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[ExpectedDate]]]',
  //         ExpectedDate
  //       );

  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[ServiceName]]]',
  //         this.ServiceName
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[ServicePrice]]]',
  //         this.ServicePrice
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         'Current: [[[StartFrom]]]',
  //         ''
  //       );

  //       if (this.ServiceName == 'Account Buy') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired',
  //           'Selected Account'
  //         );
  //       }
  //       if (this.ServiceName == 'Low Priority') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired',
  //           'Priority Matches'
  //         );
  //       }
  //       if (this.ServiceName == 'Battle Cup') {
  //         this.emailTemplate = this.emailTemplate.replace('Desired', 'Tier');
  //       }
  //       if (this.ServiceName == 'Behaviour Score') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired',
  //           'Behaviour Score'
  //         );
  //       }
  //       if (this.ServiceName == 'Normal Wins') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired: ',
  //           ''
  //         );
  //       }
  //       if (this.ServiceName == 'Dota2 Coaching') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired',
  //           'Coaching Hours'
  //         );
  //       }
  //       if (this.ServiceName == 'Rank Calibration') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Desired',
  //           'Calibration Matches'
  //         );
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Current',
  //           'Current Rank'
  //         );
  //       }
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[Desired]]]',
  //         this.Desired
  //       );

  //       if (this.Streaming == '') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Streaming: [[[Streaming]]]<br />',
  //           ''
  //         );
  //       }
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[Streaming]]]',
  //         this.Streaming
  //       );
  //       if (this.SpecificHero == '') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Specific Hero: [[[SpecificHero]]]<br />',
  //           ''
  //         );
  //       }
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[SpecificHero]]]',
  //         this.SpecificHero
  //       );
  //       if (
  //         this.ServiceName == 'Dota2 Duo Boost' ||
  //         this.ServiceName == 'Dota2 solo Boost'
  //       ) {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'is Duo Queue: [[[DuoQueue]]]<br />',
  //           ''
  //         );
  //       }
  //       if (this.DuoQueue == '') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'is Duo Queue: [[[DuoQueue]]]<br />',
  //           ''
  //         );
  //       }

  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[DuoQueue]]]',
  //         this.DuoQueue
  //       );

  //       if (this.PriorityCompletion == '') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Priority Completion: [[[PriorityCompletion]]]<br />',
  //           ''
  //         );
  //       }

  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[PriorityCompletion]]]',
  //         this.PriorityCompletion
  //       );
  //       if (this.PlayOffline == '') {
  //         this.emailTemplate = this.emailTemplate.replace(
  //           'Play Offline: [[[PlayOffline]]]<br />',
  //           ''
  //         );
  //       }
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[PlayOffline]]]',
  //         this.PlayOffline
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[SubTotal]]]',
  //         this.SubTotal
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[SubTotal]]]',
  //         this.SubTotal
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[TaxPrice]]]',
  //         this.TaxPrice
  //       );
  //       this.emailTemplate = this.emailTemplate.replace(
  //         '[[[TotalPrice]]]',
  //         this.TotalPrice
  //       );

  //       Email.send({
  //         Host: 'smtp.elasticemail.com',
  //         port: 2525,
  //         Username: 'insightrank@gmail.com',
  //         Password: 'D4645D15ED59108EC8C3112AB14209CCDEBF',
  //         To: 'dota2mmrboostofficial@outlook.com',
  //         From: 'insightrank@gmail.com',
  //         Subject: 'Order ' + this.OrderId + ' Received',
  //         Body: this.emailTemplate,
  //       }).then((message: any) => {
  //         Swal.fire(
  //           'Order Confirmed!',
  //           'Dear Customer, thanks for using our services. <br>Your order has been confirmed. <br><strong>' +
  //             this.OrderId +
  //             '</strong> is your order number.<br> kindly write this order number in our chat box and our team will contact you shortly.',
  //           'success'
  //         );
  //       });
  //     });
   
  // }
  public showSuccess: boolean = false;
  public showCancel: boolean = false;
  public showError: boolean = false;
  emailTemplate: string;
  public payPalConfig?: IPayPalConfig;
  //Production clientId:  'AbCw2LI9oSXd67fBjYZjlRSwxvT_aJXi1KVHyOi5DzUIzuaEVU0IuKzrR9xS4_TikRJ7hT9iK22fMSLh',
 // Sandbox clientId :  AU0uOWCc1B3uXzh911jMzAxxcefz5LbfhiqYZouwhYQrfCHvxCvEoeM2awVIV0hsEOcVCDuvqbzqZtL0
  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'AbCw2LI9oSXd67fBjYZjlRSwxvT_aJXi1KVHyOi5DzUIzuaEVU0IuKzrR9xS4_TikRJ7hT9iK22fMSLh', 
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.TotalPrice,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.TotalPrice,
                  },
                },
              },
              items: [
                {
                  name: this.ServiceName,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.TotalPrice,
                  },
                },
              ],
            },
          ],
        },
      
      advanced: { commit: 'true' },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },

      onClick: () => {},
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        this.getEmailtempalte();
        this.showSuccess = true;
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data) => {
        this.Result = data;
        //this.OrderId = 'D2MMR-' + this.Result.id;
        
        
      },
      onCancel: (data, actions) => {},
      onError: (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed...',
          text: 'Something went wrong!',
        });
      },
    };
  }

  getEmailtempalte(){
    this.OrderId = Guid.newGuid();
    this.httpClient
          .get('assets/emailTemplaate.txt', { responseType: 'text' })
          .subscribe((data1) => {
            this.emailTemplate = data1;
            var todayDate = formatDate(new Date(), 'dd.MM.yyyy', 'en');
            this.emailTemplate = this.emailTemplate.replace(
              '[[[OrderNumber]]]',
              this.OrderId
            );
            if (this.ServiceName == 'Normal Wins') {
                      this.emailTemplate = this.emailTemplate.replace(
                        'Desired: ',
                        ''
                      );
                    }
            this.emailTemplate = this.emailTemplate.replace(
              '[[[OrderNumber]]]',
              this.OrderId
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[OrderNumber]]]',
              this.OrderId
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[TodayDate]]]',
              todayDate
            );
            var ExpectedDate = formatDate(
              new Date().setDate(new Date().getDate() + this.ExpectedDays),
              'dd.MM.yyyy',
              'en'
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[ExpectedDate]]]',
              ExpectedDate
            );

            this.emailTemplate = this.emailTemplate.replace(
              '[[[ServiceName]]]',
              this.ServiceName
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[ServicePrice]]]',
              this.ServicePrice
            );
            if (this.StartFrom == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'Current: [[[StartFrom]]]<br />',
                ''
              );
            }

            this.emailTemplate = this.emailTemplate.replace(
              '[[[StartFrom]]]',
              this.StartFrom
            );

            if (this.ServiceName == 'Account Buy') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Selected Account'
              );
            }
            if (this.ServiceName == 'Low Priority') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Priority Matches'
              );
            }
            if (this.ServiceName == 'Battle Cup') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Tier'
              );
            }
            if (this.ServiceName == 'Behaviour Score') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Behaviour Score'
              );
            }
            if (this.ServiceName == 'Dota2 Coaching') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Coaching Hours'
              );
            }
            if (this.ServiceName == 'Rank Calibration') {
              this.emailTemplate = this.emailTemplate.replace(
                'Desired',
                'Calibration Matches'
              );
              this.emailTemplate = this.emailTemplate.replace(
                'Current',
                'Current Rank'
              );
            }
            this.emailTemplate = this.emailTemplate.replace(
              '[[[Desired]]]',
              this.Desired
            );

            if (this.Streaming == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'Streaming: [[[Streaming]]]<br />',
                ''
              );
            }
            this.emailTemplate = this.emailTemplate.replace(
              '[[[Streaming]]]',
              this.Streaming
            );
            if (this.SpecificHero == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'Specific Hero: [[[SpecificHero]]]<br />',
                ''
              );
            }
            this.emailTemplate = this.emailTemplate.replace(
              '[[[SpecificHero]]]',
              this.SpecificHero
            );
            if (
              this.ServiceName == 'Dota2 Duo Boost' ||
              this.ServiceName == 'Dota2 solo Boost'
            ) {
              this.emailTemplate = this.emailTemplate.replace(
                'is Duo Queue: [[[DuoQueue]]]<br />',
                ''
              );
            }
            if (this.DuoQueue == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'is Duo Queue: [[[DuoQueue]]]<br />',
                ''
              );
            }
            if (this.UserFirstName != '') {
              this.emailTemplate = this.emailTemplate.replace(
                '[[[UserFullName]]]', this.UserFirstName
              );
            }
            
            if (this.UserCountry != '') {
              this.emailTemplate = this.emailTemplate.replace(
                '[[[UserCountry]]]', this.UserCountry
              );
            }
            if (this.UserDiscord != '') {
              this.emailTemplate = this.emailTemplate.replace(
                '[[[UserDiscord]]]', this.UserDiscord
              );
            }
            if (this.UserEmail != '') {
              this.emailTemplate = this.emailTemplate.replace(
                '[[[UserEmail]]]', this.UserEmail
              );
            }
            if (this.UserOrderNote != '') {
              this.emailTemplate = this.emailTemplate.replace(
                '[[[OrderNote]]]', this.UserOrderNote
              );
            }

            this.emailTemplate = this.emailTemplate.replace(
              '[[[DuoQueue]]]',
              this.DuoQueue
            );

            if (this.PriorityCompletion == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'Priority Completion: [[[PriorityCompletion]]]<br />',
                ''
              );
            }

            this.emailTemplate = this.emailTemplate.replace(
              '[[[PriorityCompletion]]]',
              this.PriorityCompletion
            );
            if (this.PlayOffline == '') {
              this.emailTemplate = this.emailTemplate.replace(
                'Play Offline: [[[PlayOffline]]]<br />',
                ''
              );
            }
            this.emailTemplate = this.emailTemplate.replace(
              '[[[PlayOffline]]]',
              this.PlayOffline
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[SubTotal]]]',
              this.SubTotal
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[SubTotal]]]',
              this.SubTotal
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[TaxPrice]]]',
              this.TaxPrice
            );
            this.emailTemplate = this.emailTemplate.replace(
              '[[[TotalPrice]]]',
              this.TotalPrice
            );

            Email.send({
              Host: 'smtp.elasticemail.com',
              port: 2525,
              Username: 'insightrank@gmail.com',
              Password: '6E6944EBAFCA8C9164DFF4F23651396E8EEC',//12E2D4C4B63385F0DC371AA120B60A513705',//D4645D15ED59108EC8C3112AB14209CCDEBF
              To: 'dota2mmrboostofficial@outlook.com',
              From: 'insightrank@gmail.com',
              Subject: 'order' + this.OrderId,
              Body: this.emailTemplate,
            });
            
            Email.send({
              Host: 'smtp.elasticemail.com',
              port: 2525,
              Username: 'insightrank@gmail.com',
              Password: '6E6944EBAFCA8C9164DFF4F23651396E8EEC',//12E2D4C4B63385F0DC371AA120B60A513705',//D4645D15ED59108EC8C3112AB14209CCDEBF
              To: this.UserEmail,
              From: 'insightrank@gmail.com',
              Subject: 'order' + this.OrderId,
              Body: this.emailTemplate,
            }).then((message: any) => {
              Swal.fire(
                'Order Confirmed!',
                'Dear Customer, thanks for using our services. <br>Your order has been confirmed. <br><strong>' + this.OrderId +
                  '</strong> is your order number.<br> kindly write this order number in our chat box and our team will contact you shortly.',
                'success'
              ).then(()=>{
                window.location.href = 'https://dota2mmrboost.com';
              });
            });
          });
  }
  paymentHandler: any = null;

  makePayment() {
    debugger;
    //this.getEmailtempalte();
    if(this.validForm){
      const paymentHandler = (<any>window).StripeCheckout.configure({
        key: 'pk_live_51MuDLCAotSmaYethjGs3EJZE3nX3UyEFms4QyOn2LGvO3DGV4TmnPyNM9NOAfdzVR9WdvR7IKT4RfFmT7qUilCDS00awmdPacL',
        locale: 'auto',
        token: function (stripeToken: any) {
          paymentStripe(stripeToken.id);
        },
      });
      const paymentStripe = (data: any) => {
        let object = {Email:this.CustomerEmail,Amount:this.TotalPrice,Description: this.ServiceName,Token:data};
        this.stripeService.makePayment(object).subscribe((data1: any) => {
         
         
          if(data1.data === "Success")
          {
            this.getEmailtempalte();
            //window.open("https://dota2mmrboost.com", "_self");
          }
        });
      };
      paymentHandler.open({
        name: 'Dota2MMRBoost',
        email: this.CustomerEmail,
        amount: parseInt(this.TotalPrice) * 100,
      });
    }
    
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';

      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_live_51MuDLCAotSmaYethjGs3EJZE3nX3UyEFms4QyOn2LGvO3DGV4TmnPyNM9NOAfdzVR9WdvR7IKT4RfFmT7qUilCDS00awmdPacL',
          locale: 'auto',
          token: function (stripeToken: any) {
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }
  
}

class Guid {
  static newGuid() {
    return 'D2MMRxxxxxxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 10 | 0,
        v = c == 'x' ? r : (r & 0x2 | 0x4);
      return v.toString(16);
    });
  }
}