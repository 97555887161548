<form #demo>
  <div class="row">
    <div class="col-md-7">
      <div class="container text-center m-5">
        <div class="separator">
          <div class="line"></div>
          <p class="h3ReplaceHeading orderText">ORDER</p>
          <div class="line"></div>
        </div>
        <div class="row mb-4">
          <!-- <div class="col-md-6">
            <p class="text-white text-left">Current Medal <span style="color: red">&#42;</span></p>
            <select
            aria-colspan=""
            
            id="select-medal"
            class="form-control"
            name="attribute_select-medal"
          >
            <option value="Unranked">Unranked</option>
            <option value="Herald">Herald</option>
            <option value="Guardian">Guardian</option>
            <option value="Crusader">Crusader</option>
            <option value="Archon">Archon</option>
            <option value="Legend">Legend</option>
            <option value="Ancient">Ancient</option>
            <option value="Divine">Divine</option>
            <option value="Immortal">Immortal</option>
          </select>
          </div> -->
          <div class="col-md-12">
            <p class="text-white text-left">Hero <span style="color: red">&#42;</span></p>
            <input
              type="text"
              [(ngModel)]="HeroName"
              name="attribute_currentTier"
              class="form-control"
              id="heroName"
            />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6">
            <p class="text-white text-left">Current Tier - Level <span style="color: red">&#42;</span></p>
            <select
            aria-colspan=""
            
            id="select-currentTier"
            class="form-control"
            name="attribute_select-currentTier"
            [(ngModel)]="currentTier"
            (change)="ChangeMedal()"
            [value]="currentTier"
            #CurrentTier
          >
          <option value="">-- Please Select --</option>
          <option value="0">No Tier - Level</option>
          <option value="1">Bronze - Level 1</option>
          <option value="2">Bronze - Level 2</option>
          <option value="3">Bronze - Level 3</option>
          <option value="4">Bronze - Level 4</option>
          <option value="5">Bronze - Level 5</option>
          <option value="6">Silver - Level 6</option>
          <option value="7">Silver - Level 7</option>
          <option value="8">Silver - Level 8</option>
          <option value="9">Silver - Level 9</option>
          <option value="10">Silver - Level 10</option>
          <option value="11">Silver - Level 11</option>
          <option value="12">Gold - Level 12</option>
          <option value="13">Gold - Level 13</option>
          <option value="14">Gold - Level 14</option>
          <option value="15">Gold - Level 15</option>
          <option value="16">Gold - Level 16</option>
          <option value="17">Gold - Level 17</option>
          <option value="18">Platinum - Level 18</option>
          <option value="19">Platinum - Level 19</option>
          <option value="20">Platinum - Level 20</option>
          <option value="21">Platinum - Level 21</option>
          <option value="22">Platinum - Level 22</option>
          <option value="23">Platinum - Level 23</option>
          <option value="24">Platinum - Level 24</option>
          <option value="25">Master - Level 25</option>
          <option value="26">Master - Level 26</option>
          <option value="27">Master - Level 27</option>
          <option value="28">Master - Level 28</option>
          <option value="29">Master - Level 29</option>
          </select>
          </div>
          <div class="col-md-6">
            <p class="text-white text-left">Desired Tier - Level <span style="color: red">&#42;</span></p>
            <select
            aria-colspan=""
            
            id="select-desired"
            class="form-control"
            name="attribute_select-desired"
            (change)="ChangeMedal()"
            [(ngModel)]="desiredTier"
            [value]="desiredTier"
            #DesiredTier
          >
          <option value="">-- Please Select --</option>
          <option value="0">No Tier - Level</option>
          <option value="1">Bronze - Level 1</option>
          <option value="2">Bronze - Level 2</option>
          <option value="3">Bronze - Level 3</option>
          <option value="4">Bronze - Level 4</option>
          <option value="5">Bronze - Level 5</option>
          <option value="6">Silver - Level 6</option>
          <option value="7">Silver - Level 7</option>
          <option value="8">Silver - Level 8</option>
          <option value="9">Silver - Level 9</option>
          <option value="10">Silver - Level 10</option>
          <option value="11">Silver - Level 11</option>
          <option value="12">Gold - Level 12</option>
          <option value="13">Gold - Level 13</option>
          <option value="14">Gold - Level 14</option>
          <option value="15">Gold - Level 15</option>
          <option value="16">Gold - Level 16</option>
          <option value="17">Gold - Level 17</option>
          <option value="18">Platinum - Level 18</option>
          <option value="19">Platinum - Level 19</option>
          <option value="20">Platinum - Level 20</option>
          <option value="21">Platinum - Level 21</option>
          <option value="22">Platinum - Level 22</option>
          <option value="23">Platinum - Level 23</option>
          <option value="24">Platinum - Level 24</option>
          <option value="25">Master - Level 25</option>
          <option value="26">Master - Level 26</option>
          <option value="27">Master - Level 27</option>
          <option value="28">Master - Level 28</option>
          <option value="29">Master - Level 29</option>
          <option value="30">Grand Master - Level 30</option>
          </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 text-center">
      <div class="container VerticleStyling">
        <h5
          class="text-white pt-3"
          style="font-size: 18px; font-family: Arial, Helvetica, sans-serif"
        >
          Your Order
        </h5>
        <div class="TopStyling">
          <p class="h3ReplaceHeading text-white">
             Hours Dota 2 Coaching
          </p>
        </div>
        <div class="row m-1 mt-3">
          <table class="table table-borderless table-sm">
            <!-- <tr>
              <td width="5%" style="vertical-align:middle;">
                <span class="material-icons" style="color: #2CA8FF; font-size: 40px;">
                  people_alt
                </span>

              </td>
              <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
                <label class="text-white ml-2 duoqueuestyle duoCustomStyle"><b>Duo Queue </b> &nbsp;<small>
                    (+{{DuoQueuePercentage}}%)</small></label>&nbsp;
                <span class="material-icons" data-toggle="tooltip" data-placement="top"
                  title="You can play on your own account, and group with us for your order!"
                  style="color: white; font-size: 15px;">
                  info
                </span>
              </td>
              <td style="padding: -6rem  !important;" class="text-left" width="10%">

                <ui-switch (change)="onDuoChange($event)" [checked]="IsDuoQueueChecked" class='MarginLeftForSwitch'
                  color="#2CA8FF" style="font-size: 30px;" defaultBgColor="white" labelOn="Yes"
                  labelOff="No"></ui-switch>
              </td>

            </tr> -->
            <tr>
              <td width="5%" style="vertical-align: middle">
                <span
                  class="material-icons"
                  style="color: #ff5555; font-size: 40px"
                >
                  movie
                </span>
              </td>
              <td
                style="padding: 0rem !important; vertical-align: middle"
                class="text-left"
                width="35%"
              >
                <label
                  class="text-white ml-2 duoqueuestyle StreamingCustomStyle"
                  ><b>Streaming </b> &nbsp;<small style="font-size: 60%">
                    (+{{ StreamingPercentage }}%)</small
                  ></label
                >&nbsp;
                <span
                  class="material-icons"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="You can watch us play on your account, through a private live stream broadcast or VOD (if you want to watch it later)"
                  style="color: #2ca8ff; font-size: 15px"
                >
                  info
                </span>
              </td>
              <td
                style="padding: -6rem !important"
                class="text-left"
                width="10%"
              >
                <ui-switch
                  (change)="onStreamingChange($event)"
                  [checked]="IsStreamingChecked"
                  class="MarginLeftForSwitch"
                  color="#2CA8FF"
                  style="font-size: 30px"
                  defaultBgColor="white"
                  labelOn="Yes"
                  labelOff="No"
                ></ui-switch>
              </td>
            </tr>
            <tr>
              <td width="5%" style="vertical-align: middle">
                <span
                  class="material-icons"
                  style="color: #ffb236; font-size: 40px"
                >
                  bolt
                </span>
              </td>
              <td
                style="padding: 0rem !important; vertical-align: middle"
                class="text-left"
                width="35%"
              >
                <label class="text-white ml-2 duoqueuestyle PriorityCustomStyle"
                  ><b>Priority Completion </b> &nbsp;<small
                    style="font-size: 60%"
                  >
                    (+{{ PriorityPercentage }}%)</small
                  ></label
                >&nbsp;
                <span
                  class="material-icons"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="If you purchase with this option, we will push your order to the top of the queue!"
                  style="color: #2ca8ff; font-size: 15px"
                >
                  info
                </span>
              </td>
              <td
                style="padding: -6rem !important"
                class="text-left"
                width="10%"
              >
                <ui-switch
                  (change)="onPriorityChange($event)"
                  [checked]="IsPriorityCompletionChecked"
                  class="MarginLeftForSwitch"
                  color="#2CA8FF"
                  style="font-size: 30px"
                  defaultBgColor="white"
                  labelOn="Yes"
                  labelOff="No"
                ></ui-switch>
              </td>
            </tr>
            <tr>
              <td width="5%" style="vertical-align: middle">
                <span
                  class="material-icons"
                  style="color: #666666; font-size: 40px"
                >
                  visibility_off
                </span>
              </td>
              <td
                style="padding: 0rem !important; vertical-align: middle"
                class="text-left"
                width="35%"
              >
                <label
                  class="text-white ml-2 duoqueuestyle PlayOfflineCustomStyle"
                  ><b>Play offline </b></label
                >
              </td>
              <td
                style="padding: -6rem !important"
                class="text-left"
                width="10%"
              >
                <ui-switch
                  (change)="onIsPlayOfflineCheckedChange($event)"
                  [checked]="IsPlayOfflineChecked"
                  class="MarginLeftForSwitch"
                  color="#2CA8FF"
                  style="font-size: 30px"
                  defaultBgColor="white"
                  labelOn="Yes"
                  labelOff="No"
                ></ui-switch>
              </td>
            </tr>
          </table>
        </div>

        
      
       
        
       
        <div class="separator">
          <div class="line"></div>
          <p class="h3ReplaceHeading orderText">TOTAL</p>
          <div class="line"></div>
        </div>
        <p class="h3ReplaceHeading text-white">
         
          ${{ TotalPrice | number : "1.2-2" }}
        </p>
        <!-- <div class="ml-5 mr-5">
          <app-Paypal  
          [ServiceName]="'Dota2 Coaching'"
          [StartFrom]="''"
          [ExpectedDays]="1"
          [Desired]="value"
          [Streaming]="Streaming"
          [SpecificHero]="''"
          [PriorityCompletion]="PriorityCompletion"
          [PlayOffline]="PlayOffline"
          [ServicePrice]="TotalPrice"
          [SubTotal]="TotalPrice"
          [TaxPrice]="tax"
          [DuoQueue]="DuoQueue"
          [TotalPrice]="FullPrice"></app-Paypal>
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div> -->
        <button
          type="button"
          class="btn btn-primary mt-3 mb-2"
          style="width: 80%"
          (click)="GoToCheckout()"
        >
          Checkout
        </button>

        <img class="mb-3" src="../../assets/paypalImages.png" width="70%" />
      </div>
    </div>
  </div>
</form>
