import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-battlecup',
  templateUrl: './battlecup.component.html',
  styleUrls: ['./battlecup.component.scss']
})
export class BattleCupComponent {
  constructor(public router:Router){}
  IsDuoQueueChecked: boolean = false;
  DuoQueuePercentage: number = 100;
  StreamingPercentage: number =15;
  HeroPreferencesPercentage: number = 15;

  Streaming :string = '';
  HeroPreferences :string = '';
  PlayOffline :string = '';
  tax: number;
  FullPrice: number;

  IsStreamingChecked: boolean = false;
  IsPriorityCompletionChecked: boolean = false;
  IsHeroPreferencesChecked: boolean = false;
  IsPlayOfflineChecked: boolean = false;
  imagePath = "";


  min100: number = 100;
  TotalPrice: number = 7.15;
  currrentPrice: number = 7.15;
  couponName: string = 'ECHO10SLAM';
  actualPrice: number;
  couponList: any;
  FirstTimeInit: boolean = false;
  // value: number = 123;
  value: number = 3;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
    ]
  };
  hideActualPrice: boolean = false;
  updatedPrice: number;
  AfterDuoChecked: boolean = false;
  ngOnInit() {
    this.couponList = new Array();
    this.actualPrice = this.TotalPrice;
    this.couponList = [
      { 'Name': 'ECHO10SLAM', 'Value': 10 }
    ]
    this.genericPriceFunction(this.value);
  }
  genericPriceFunction(value: any) {
    
    if(this.value ==3 || this.value == 4)
    {
      this.TotalPrice = 21.50;
    }
    if(this.value == 5)
    {
      this.TotalPrice = 31.99;
    }
    if(this.value == 6)
    {
      this.TotalPrice = 42.99;
    }
    if(this.value == 7)
    {
      this.TotalPrice = 53.99;
    }
    if(this.value == 8)
    {
      this.TotalPrice = 64.99;
    }
    //this.TotalPrice = value * this.currrentPrice;
    this.actualPrice = this.TotalPrice;
    var currentCouponList = this.couponList.find(x => x.Name == this.couponName)
    if (currentCouponList != null) {
      if (currentCouponList != undefined) {
        this.TotalPrice = this.TotalPrice - (this.TotalPrice * (currentCouponList.Value / 100))
        this.hideActualPrice = true;
      }
    }
    else {
      this.TotalPrice = this.actualPrice;
      this.hideActualPrice = false;
    }
    this.CheckIfStreamingChecked();
    this.CheckIfHeroPrefrenceChecked();


   
    if (this.IsHeroPreferencesChecked == true) {
      this.HeroPreferences = 'Yes';
    } else {
      this.HeroPreferences = 'No';
    }
    if (this.IsStreamingChecked == true) {
      this.Streaming = 'Yes';
    } else {
      this.Streaming = 'No';
    }
    
    if (this.IsPlayOfflineChecked == true) {
      this.PlayOffline = 'Yes';
    } else {
      this.PlayOffline = 'No';
    }
    var a = parseFloat(this.TotalPrice.toString()).toFixed(2);
    this.TotalPrice = parseInt(a);
    this.tax = (this.TotalPrice / 100) * 4;
    this.FullPrice = this.TotalPrice + this.tax;

  }

  CheckIfDuoChecked() {
    if (this.IsDuoQueueChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.DuoQueuePercentage / 100))
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.DuoQueuePercentage / 100))
    }
  }
  CheckIfStreamingChecked() {
    if (this.IsStreamingChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.StreamingPercentage / 100))
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.StreamingPercentage / 100))
    }
  }

  CheckIfHeroPrefrenceChecked() {
    if (this.IsHeroPreferencesChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.HeroPreferencesPercentage / 100)) ;
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.HeroPreferencesPercentage / 100));
    }
  }
  onUserChange(event: any): void {
    // this.value = event.value;

    this.genericPriceFunction(this.value);
  }

  onKey(event: any) { // without type info
    this.couponName = event.target.value;
    this.genericPriceFunction(this.value);
  }

  CalculateFinalResult(min: any, max: any) {
    const stepSize = 1000;
    const absoluteMin = 0;
    const absoluteMax = 10000;
    const totalSteps = absoluteMax / stepSize;
    let sum = 0;
    let minFound = false;
    let maxFound = false;
    for (var multiplier = 1; multiplier <= totalSteps; multiplier++) {
      let end = multiplier * stepSize;
      let start = end - stepSize;
      if (min >= start && max < end) {
        sum = (max - min) * multiplier;
        break;
      }
      if (min > end) {
        continue;
      }
      if (min >= start && min <= end) {
        sum += (end - min) * multiplier;
        console.log('min found', sum)
        continue;
      }
      if (max >= start && max <= end) {
        sum += (end - max) * multiplier;
        break;
      }
      sum += (end * multiplier)
    }
    return sum;
  }

  MinsValueButton() {
    if (this.value > 3) {
      this.value -= 1;
      this.genericPriceFunction(this.value);
    }
  }
  PlusValueButton() {
    
    if (this.value < 8) {
      this.value += 1;
      this.genericPriceFunction(this.value);

    }
  }

  onKeyMin(value: number) {
    if (this.value > 3) {
      this.value -= 1;
    }
  }
  onKeyMax(value: number) {
    if (this.value <= 8) {
      this.value += 1;
      // this.genericPriceFunction(this.value);
    }
  }
  // updatedPrice: number;
  onDuoChange(event: any) {
    
    this.IsDuoQueueChecked = event;
    this.genericPriceFunction(this.value);
  }
  onStreamingChange(event: any) {
    
    this.IsStreamingChecked = event;
    this.genericPriceFunction(this.value);
  }

  onHeroPreferencesChange(event: any) {
    this.IsHeroPreferencesChecked = event;
    this.genericPriceFunction(this.value);

  }

  onIsPlayOfflineCheckedChange(event: any) {
    this.IsPlayOfflineChecked = event;
    this.genericPriceFunction(this.value);

  }

  GoToCheckout(){
    localStorage.setItem('ServiceName','Battle Cup');
    localStorage.setItem('StartFrom','');
    localStorage.setItem('ExpectedDays','1');
    localStorage.setItem('Desired','Battle Cup Tier : '+this.value.toString());
    localStorage.setItem('Streaming',this.IsStreamingChecked == true?'Yes':'No');
    localStorage.setItem('DuoQueue','');
    localStorage.setItem('SpecificHero',this.IsHeroPreferencesChecked == true?'Yes':'No');
    localStorage.setItem('PriorityCompletion','');
    localStorage.setItem('PlayOffline',this.IsPlayOfflineChecked == true?'Yes':'No');
    localStorage.setItem('ServicePrice',this.TotalPrice.toString());
    localStorage.setItem('SubTotal',this.TotalPrice.toString());
    localStorage.setItem('TaxPrice',this.tax.toString());
    localStorage.setItem('TotalPrice',this.FullPrice.toString());
    localStorage.setItem('CustomDesiredLabel','true');

     window.open("https://dota2mmrboost.com/checkout", "_blank");

    // this.router.navigateByUrl('/Checkout');
  }

}
