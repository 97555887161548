import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import Swal from 'sweetalert2';
declare let Email: any;
@Component({
  selector: 'app-Paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.css'],
})
export class PaypalComponent implements OnInit {
  @Input() ServiceName: string ='';
  @Input() StartFrom: string ='0';
  @Input() DuoQueue: string ='0';
  @Input() ExpectedDays: number = 0;
  @Input() Desired: string ='0';
  @Input() Streaming: string ='0';
  @Input() SpecificHero: string ='0';
  @Input() PriorityCompletion: string ='0';
  @Input() PlayOffline: string ='0';
  @Input() ServicePrice: string ='0';
  @Input() SubTotal: string ='0';
  @Input() TaxPrice: string ='0';
  @Input() TotalPrice: string ='0';
  Result: any;
  OrderId: any;
  constructor(public httpClient: HttpClient) {
  }
  ngOnInit(): void {
   
    this.initConfig();
    //throw new Error('Method not implemented.');
  }
  public showSuccess: boolean = false;
  public showCancel: boolean = false;
  public showError: boolean = false;
  emailTemplate:string;
  public payPalConfig?: IPayPalConfig;

  private initConfig(): void {

    this.payPalConfig = {
      currency: 'USD',
      clientId:
        'AbCw2LI9oSXd67fBjYZjlRSwxvT_aJXi1KVHyOi5DzUIzuaEVU0IuKzrR9xS4_TikRJ7hT9iK22fMSLh',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.TotalPrice,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.TotalPrice,
                  },
                },
              },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: this.TotalPrice,
                  },
                },
              ],
            },
          ],
        },
        // advanced: { extraQueryParams: [ { name: "disable-funding", value:"credit,card"} ] } ,
        advanced: { commit: "true" } ,
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data) => {
        
        
        this.Result = data;
        this.OrderId = 'D2MMR-'+this.Result.id;
        this.httpClient.get('assets/emailTemplaate.txt', { responseType: 'text' })
        .subscribe(data1 => {
          this.emailTemplate = data1;
          var todayDate= formatDate(new Date(), 'dd.MM.yyyy', 'en');
          this.emailTemplate = this.emailTemplate.replace('[[[OrderNumber]]]',this.OrderId);
          this.emailTemplate = this.emailTemplate.replace('[[[OrderNumber]]]',this.OrderId);
          this.emailTemplate = this.emailTemplate.replace('[[[OrderNumber]]]',this.OrderId);
          this.emailTemplate = this.emailTemplate.replace('[[[TodayDate]]]',todayDate);
          var ExpectedDate= formatDate(new Date().setDate(new Date().getDate() + this.ExpectedDays), 'dd.MM.yyyy', 'en');
          this.emailTemplate = this.emailTemplate.replace('[[[ExpectedDate]]]',ExpectedDate);
          
          this.emailTemplate = this.emailTemplate.replace('[[[ServiceName]]]',this.ServiceName);
          this.emailTemplate = this.emailTemplate.replace('[[[ServicePrice]]]',this.ServicePrice);
          if(this.StartFrom =='')
          {
            this.emailTemplate = this.emailTemplate.replace('Current: [[[StartFrom]]]<br />','')
          }
          
          this.emailTemplate = this.emailTemplate.replace('[[[StartFrom]]]',this.StartFrom);
          
          if(this.ServiceName == 'Account Buy')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Selected Account");
          }
          if(this.ServiceName == 'Low Priority')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Priority Matches");
          }
          if(this.ServiceName == 'Battle Cup')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Tier");
          }
          if(this.ServiceName == 'Behaviour Score')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Behaviour Score");
          }
          if(this.ServiceName == 'Dota2 Coaching')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Coaching Hours");
          }
          if(this.ServiceName == 'Rank Calibration')
          {
          this.emailTemplate = this.emailTemplate.replace('Desired',"Calibration Matches");
          this.emailTemplate = this.emailTemplate.replace('Current',"Current Rank");
          }
          this.emailTemplate = this.emailTemplate.replace('[[[Desired]]]',this.Desired);
          
          if(this.Streaming =='')
          {
            this.emailTemplate = this.emailTemplate.replace('Streaming: [[[Streaming]]]<br />','')
          }
          this.emailTemplate = this.emailTemplate.replace('[[[Streaming]]]',this.Streaming);
          if(this.SpecificHero =='' )
          {
            this.emailTemplate = this.emailTemplate.replace('Specific Hero: [[[SpecificHero]]]<br />','')
          }
          this.emailTemplate = this.emailTemplate.replace('[[[SpecificHero]]]',this.SpecificHero);
          if(this.ServiceName =='Dota2 Duo Boost' || this.ServiceName =='Dota2 solo Boost' )
          {
            this.emailTemplate = this.emailTemplate.replace('is Duo Queue: [[[DuoQueue]]]<br />','')
          }
          if(this.DuoQueue =='' )
          {
            this.emailTemplate = this.emailTemplate.replace('is Duo Queue: [[[DuoQueue]]]<br />','')
          }
          
          
          this.emailTemplate = this.emailTemplate.replace('[[[DuoQueue]]]',this.DuoQueue);
          
          if(this.PriorityCompletion =='' )
          {
            this.emailTemplate = this.emailTemplate.replace('Priority Completion: [[[PriorityCompletion]]]<br />','')
          }
          
          this.emailTemplate = this.emailTemplate.replace('[[[PriorityCompletion]]]',this.PriorityCompletion);
          if(this.PlayOffline =='' )
          {
            this.emailTemplate = this.emailTemplate.replace('Play Offline: [[[PlayOffline]]]<br />','')
          }
          this.emailTemplate = this.emailTemplate.replace('[[[PlayOffline]]]',this.PlayOffline);
          this.emailTemplate = this.emailTemplate.replace('[[[SubTotal]]]',this.SubTotal);
          this.emailTemplate = this.emailTemplate.replace('[[[SubTotal]]]',this.SubTotal);
          this.emailTemplate = this.emailTemplate.replace('[[[TaxPrice]]]',this.TaxPrice);
          this.emailTemplate = this.emailTemplate.replace('[[[TotalPrice]]]',this.TotalPrice);


          Email.send({
            Host : 'smtp.elasticemail.com',
            port : 2525,
            Username: 'insightrank@gmail.com',
            Password: 'D4645D15ED59108EC8C3112AB14209CCDEBF',
            To: 'dota2mmrboostofficial@outlook.com',
            From: 'insightrank@gmail.com',
            Subject : 'Order '+this.Result.id+' Received',
            Body : this.emailTemplate
            
            }).then( (message:any) => {
              Swal.fire(
                'Order Confirmed!',
                'Dear Customer, thanks for using our services. <br>Your order has been confirmed. <br><strong>DMMR-'+data.id+'</strong> is your order number.<br> kindly write this order number in our chat box and our team will contact you shortly.',
                'success'
              );
            });
        });
       
         
        
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        
      },
      onError: (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed...',
          text: 'Something went wrong!',
        })
        
       
      },
     
    };
  }
}


