import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider1',
  templateUrl: './lowpriority.component.html',
  styleUrls: ['./lowpriority.component.scss']
})
export class lowpriorityComponent {

  constructor(public router:Router){

  }
  IsDuoQueueChecked: boolean = false;
  DuoQueuePercentage: number = 80;
  StreamingPercentage: number =15;
  PriorityPercentage: number = 20;

  DuoQueue: string;
  Streaming: string;
  HeroPreferences: string;
  PriorityCompletion: string;
  PlayOffline: string;
  tax: number;
  FullPrice: number;


  IsStreamingChecked: boolean = false;
  IsPriorityCompletionChecked: boolean = false;
  IsHeroPreferencesChecked: boolean = false;
  IsPlayOfflineChecked: boolean = false;
  imagePath = "";


  min100: number = 100;
  TotalPrice: number = 3.5;
  currrentPrice: number = 3.5;
  couponName: string = 'ECHO10SLAM';
  actualPrice: number;
  couponList: any;
  FirstTimeInit: boolean = false;
  // value: number = 123;
  value: number = 5;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 }
    ]
  };
  hideActualPrice: boolean = false;
  updatedPrice: number;
  AfterDuoChecked: boolean = false;
  ngOnInit() {
    this.couponList = new Array();
    this.actualPrice = this.TotalPrice;
    this.couponList = [
      { 'Name': 'ECHO10SLAM', 'Value': 10 }
    ]
    this.genericPriceFunction(this.value);
  }
  genericPriceFunction(value: any) {
    
    this.TotalPrice = value * this.currrentPrice;
    this.actualPrice = this.TotalPrice;
    var currentCouponList = this.couponList.find(x => x.Name == this.couponName)
    if (currentCouponList != null) {
      if (currentCouponList != undefined) {
        this.TotalPrice = this.TotalPrice - (this.TotalPrice * (currentCouponList.Value / 100))
        this.hideActualPrice = true;
      }
    }
    else {
      this.TotalPrice = this.actualPrice;
      this.hideActualPrice = false;
    }
    this.CheckIfDuoChecked();
    this.CheckIfStreamingChecked();
    this.CheckIfPriorityChecked();
    if (this.IsDuoQueueChecked == true) {
    this.DuoQueue = 'Yes';
    } else {
      this.DuoQueue = 'No';
    }
    if (this.IsHeroPreferencesChecked == true) {
      this.HeroPreferences = 'Yes';
    } else {
      this.HeroPreferences = 'No';
    }
    if (this.IsStreamingChecked == true) {
      this.Streaming = 'Yes';
    } else {
      this.Streaming = 'No';
    }
    if(this.IsPriorityCompletionChecked == true)
    {
      this.PriorityCompletion = "Yes"
    }
    else
    {
      this.PriorityCompletion = "No"
    }
    if(this.IsPlayOfflineChecked == true)
    {
      this.PlayOffline = "Yes";
    }
    else
    {
      this.PlayOffline = "No";
    }
    var a = parseFloat(this.TotalPrice.toString()).toFixed(2);
    this.TotalPrice = parseInt(a);
    this.tax = (this.TotalPrice/100)*4;
    this.FullPrice = this.TotalPrice +this.tax;
  }

  CheckIfDuoChecked() {
    if (this.IsDuoQueueChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.DuoQueuePercentage / 100))
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.DuoQueuePercentage / 100))
    }
  }
  CheckIfStreamingChecked() {
    if (this.IsStreamingChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.StreamingPercentage / 100))
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.StreamingPercentage / 100))
    }
  }

  CheckIfPriorityChecked() {
    if (this.IsPriorityCompletionChecked == true) {
      this.TotalPrice = this.TotalPrice + (this.TotalPrice * (this.PriorityPercentage / 100)) ;
      this.actualPrice = this.actualPrice + (this.actualPrice * (this.PriorityPercentage / 100));
    }
  }
  onUserChange(event: any): void {
    // this.value = event.value;
    this.genericPriceFunction(this.value);
  }

  onKey(event: any) { // without type info
    this.couponName = event.target.value;
    this.genericPriceFunction(this.value);
  }

  CalculateFinalResult(min: any, max: any) {
    const stepSize = 1000;
    const absoluteMin = 0;
    const absoluteMax = 10000;
    const totalSteps = absoluteMax / stepSize;
    let sum = 0;
    let minFound = false;
    let maxFound = false;
    for (var multiplier = 1; multiplier <= totalSteps; multiplier++) {
      let end = multiplier * stepSize;
      let start = end - stepSize;
      if (min >= start && max < end) {
        sum = (max - min) * multiplier;
        break;
      }
      if (min > end) {
        continue;
      }
      if (min >= start && min <= end) {
        sum += (end - min) * multiplier;
        console.log('min found', sum)
        continue;
      }
      if (max >= start && max <= end) {
        sum += (end - max) * multiplier;
        break;
      }
      sum += (end * multiplier)
    }
    return sum;
  }

  MinsValueButton() {
    if (this.value > 2) {
      this.value -= 1;
      this.genericPriceFunction(this.value);
    }
  }
  PlusValueButton() {
    
    if (this.value < 10) {
      this.value += 1;
      this.genericPriceFunction(this.value);

    }
  }

  onKeyMin(value: number) {
    if (this.value > 1) {
      this.value -= 1;
    }
  }
  onKeyMax(value: number) {
    if (this.value <= 10) {
      this.value += 1;
      // this.genericPriceFunction(this.value);
    }
  }
  // updatedPrice: number;
  onDuoChange(event: any) {
    
    this.IsDuoQueueChecked = event;
    this.genericPriceFunction(this.value);
  }
  onStreamingChange(event: any) {
    
    this.IsStreamingChecked = event;
    this.genericPriceFunction(this.value);
  }

  onPriorityChange(event: any) {
    this.IsPriorityCompletionChecked = event;
    this.genericPriceFunction(this.value);

  }

  onIsPlayOfflineCheckedChange(event: any) {
    this.IsPlayOfflineChecked = event;
    this.genericPriceFunction(this.value);

  }
  GoToCheckout(){
    localStorage.setItem('ServiceName','Low Priority');
    localStorage.setItem('ServicePrice',this.TotalPrice.toString());
    localStorage.setItem('SubTotal',this.TotalPrice.toString());
    localStorage.setItem('TaxPrice',this.tax.toString());
    localStorage.setItem('TotalPrice',this.FullPrice.toString());
    localStorage.setItem('DuoQueue',this.IsDuoQueueChecked == true?'Yes':'No');
    localStorage.setItem('StartFrom','');
    localStorage.setItem('Desired','Priority Matches : '+this.value.toString());
    localStorage.setItem('Streaming',this.IsStreamingChecked == true?'Yes':'No');
    localStorage.setItem('SpecificHero','');
    localStorage.setItem('PriorityCompletion',this.IsPriorityCompletionChecked == true?'Yes':'No');
    localStorage.setItem('PlayOffline',this.IsPlayOfflineChecked == true?'Yes':'No');
    localStorage.setItem('ExpectedDays','1');
    localStorage.setItem('CustomDesiredLabel','true');


     window.open("https://dota2mmrboost.com/checkout", "_blank");

    // this.router.navigateByUrl('/Checkout');
  }
}
