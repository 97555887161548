<div class="row">
  <div class="col-md-7">
    <div class="container text-center m-5">

      <p style="font-size: 16px;font-family:Arial, Helvetica, sans-serif; font-weight: 900;" class="text-white">
          Buy 1000 MMR get 100 MMR free
      </p> 
      <div class="separator">
        <div class="line"></div>
        <p class="h3ReplaceHeading orderText">ORDER</p>
        <div class="line"></div>
      </div>
      <div class="row mb-5">
        <div class="col-md-6 text-center">
          <label class="input-label">Current MMR</label>
          <div class="form-inline justify-content-center">
            <button class="InputMaxMinusButton" (click)="MinsMinValueButton()">
              -
            </button>
            <input
              type="number"
              #MinInput
              class="input-min"
              (keyup)="onKeyMin(MinInput.value)"
              [value]="minValue"
            />
            <button class="InputMaxPlusButton" (click)="PlusMinValueButton()">
              +
            </button>
          </div>
          <div class="mt-3">
            <img
              class="ImageSizeSetting"
              *ngIf="minValue <= 153"
              src="./assets/icons/1.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 153 && minValue <= 307"
              src="./assets/icons/2.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 307 && minValue <= 461"
              src="./assets/icons/3.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 461 && minValue <= 615"
              src="./assets/icons/4.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 615 && minValue <= 769"
              src="./assets/icons/5.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 769 && minValue <= 923"
              src="./assets/icons/6.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 923 && minValue <= 1077"
              src="./assets/icons/7.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1077 && minValue <= 1231"
              src="./assets/icons/8.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1231 && minValue <= 1385"
              src="./assets/icons/9.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1385 && minValue <= 1539"
              src="./assets/icons/10.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1539 && minValue <= 1693"
              src="./assets/icons/11.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1693 && minValue <= 1847"
              src="./assets/icons/12.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 1847 && minValue <= 2001"
              src="./assets/icons/13.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2001 && minValue <= 2155"
              src="./assets/icons/14.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2155 && minValue <= 2309"
              src="./assets/icons/15.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2309 && minValue <= 2463"
              src="./assets/icons/16.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2463 && minValue <= 2617"
              src="./assets/icons/17.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2617 && minValue <= 2771"
              src="./assets/icons/18.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2771 && minValue <= 2925"
              src="./assets/icons/19.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 2925 && minValue <= 3079"
              src="./assets/icons/20.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3079 && minValue <= 3233"
              src="./assets/icons/21.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3233 && minValue <= 3387"
              src="./assets/icons/22.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3387 && minValue <= 3541"
              src="./assets/icons/23.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3541 && minValue <= 3695"
              src="./assets/icons/24.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3695 && minValue <= 3849"
              src="./assets/icons/25.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 3849 && minValue <= 4003"
              src="./assets/icons/26.png"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4003 && minValue <= 4157"
              src="./assets/icons/27.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4157 && minValue <= 4311"
              src="./assets/icons/28.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4311 && minValue <= 4465"
              src="./assets/icons/29.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4465 && minValue <= 4619"
              src="./assets/icons/30.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4619 && minValue <= 4819"
              src="./assets/icons/31.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 4819 && minValue <= 5019"
              src="./assets/icons/32.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 5019 && minValue <= 5419"
              src="./assets/icons/33.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 5419 && minValue <= 5550"
              src="./assets/icons/34.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 5550 && minValue <= 5650"
              src="./assets/icons/35.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="minValue > 5650"
              src="./assets/icons/immortal.jpg"
            />
          </div>
        </div>
        <div class="col-md-6 text-center">
          <label class="input-label">Desired MMR</label>
          <div class="form-inline justify-content-center">
            <button class="InputMaxMinusButton" (click)="MinsMaxValueButton()">
              -
            </button>
            <input
              type="number"
              #MaxInput
              class="input-min"
              (keyup)="onKeyMax(MaxInput.value)"
              [value]="maxValue"
            />
            <button class="InputMaxPlusButton" (click)="PlusMaxValueButton()">
              +
            </button>
          </div>
          <div class="mt-3">
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue <= 153"
              src="./assets/icons/1.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 153 && maxValue <= 307"
              src="./assets/icons/2.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 307 && maxValue <= 461"
              src="./assets/icons/3.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 461 && maxValue <= 615"
              src="./assets/icons/4.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 615 && maxValue <= 769"
              src="./assets/icons/5.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 769 && maxValue <= 923"
              src="./assets/icons/6.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 923 && maxValue <= 1077"
              src="./assets/icons/7.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1077 && maxValue <= 1231"
              src="./assets/icons/8.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1231 && maxValue <= 1385"
              src="./assets/icons/9.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1385 && maxValue <= 1539"
              src="./assets/icons/10.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1539 && maxValue <= 1693"
              src="./assets/icons/11.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1693 && maxValue <= 1847"
              src="./assets/icons/12.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 1847 && maxValue <= 2001"
              src="./assets/icons/13.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2001 && maxValue <= 2155"
              src="./assets/icons/14.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2155 && maxValue <= 2309"
              src="./assets/icons/15.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2309 && maxValue <= 2463"
              src="./assets/icons/16.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2463 && maxValue <= 2617"
              src="./assets/icons/17.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2617 && maxValue <= 2771"
              src="./assets/icons/18.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2771 && maxValue <= 2925"
              src="./assets/icons/19.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 2925 && maxValue <= 3079"
              src="./assets/icons/20.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3079 && maxValue <= 3233"
              src="./assets/icons/21.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3233 && maxValue <= 3387"
              src="./assets/icons/22.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3387 && maxValue <= 3541"
              src="./assets/icons/23.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3541 && maxValue <= 3695"
              src="./assets/icons/24.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3695 && maxValue <= 3849"
              src="./assets/icons/25.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 3849 && maxValue <= 4003"
              src="./assets/icons/26.png"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4003 && maxValue <= 4157"
              src="./assets/icons/27.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4157 && maxValue <= 4311"
              src="./assets/icons/28.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4311 && maxValue <= 4465"
              src="./assets/icons/29.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4465 && maxValue <= 4619"
              src="./assets/icons/30.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4619 && maxValue <= 4819"
              src="./assets/icons/31.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 4819 && maxValue <= 5019"
              src="./assets/icons/32.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 5019 && maxValue <= 5419"
              src="./assets/icons/33.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 5419 && maxValue <= 5550"
              src="./assets/icons/34.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 5550 && maxValue <= 5650"
              src="./assets/icons/35.jpg"
            />
            <img
              class="ImageSizeSetting"
              *ngIf="maxValue > 5650"
              src="./assets/icons/immortal.jpg"
            />
          </div>
        </div>
      </div>

      <ngx-slider
        [(value)]="minValue"
        [(highValue)]="maxValue"
        [options]="options"
        (userChange)="onUserChange($event)"
      ></ngx-slider>
      <i><p class="text-center mt-5" style="color: #636264">
        Displayed medals are approximate.
      </p></i>
    </div>
  </div>

  <div class="col-md-4 text-center">
    <div class="container VerticleStyling">
      <p class="text-white pt-3" style="font-size: 20px;font-weight: bold;">Your Order</p>
      <div class="TopStyling">
        <p class="text-white h3ReplaceHeading"> MMR Boost from {{ minValue }} to {{ maxValue }}</p>
        <p class="text-white"> Approximate completion time: {{ approximateDays }} days</p>
      </div>
      <div class="row m-1 mt-3">
  <table class="table table-borderless table-sm">
          <tr>
            
            <td width="5%" style="vertical-align:middle;">
              <span class="material-icons" style="color: #2CA8FF; font-size: 40px;">
                people_alt
              </span>
            </td>
            <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
              <label class="text-white ml-2 StreamingCustomStyle duoqueuestyle"><b>Duo Queue </b> &nbsp;<small style="font-size: 60%;">
                (+{{DuoQueuePercentage}}%)</small></label>
              
              <span class="material-icons" data-toggle="tooltip" data-placement="top"
                title="You can play on your own account, and group with us for your order!"
                style="color: white; font-size: 15px;">
                info
              </span>
            </td>
            <td style="padding: -6rem  !important;" class="text-left" width="10%">
              <ui-switch (change)="onDuoChange($event)" [checked]="IsDuoQueueChecked"
                class=" duoCustomStyle MarginLeftForSwitch" color="#2CA8FF" style="font-size: 30px;"
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
            </td>
           
          </tr>
          <tr>
            
            <td width="5%" style="vertical-align:middle;">
              <span class="material-icons" style="color: #FF5555; font-size: 40px;">
                movie
              </span>
            </td>
            <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
              <label class="text-white ml-2 StreamingCustomStyle duoqueuestyle"><b>Streaming </b> &nbsp;<small style="font-size: 60%;">
                  (+{{StreamingPercentage}}%)</small></label>&nbsp;
              <span class="material-icons" data-toggle="tooltip" data-placement="top"
                title="You can watch us play on your account, through a private live stream broadcast or VOD (if you want to watch it later)"
                style="color: #2CA8FF; font-size: 15px;">
                info
              </span>
            </td>
            <td style="padding: -6rem  !important;" class="text-left" width="10%">
              <ui-switch (change)="onStreamingChange($event)" [checked]="IsStreamingChecked"
                class="StreamingCustomStyle MarginLeftForSwitch" color="#2CA8FF" style="font-size: 30px;"
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
            </td>
           
          </tr>
          <tr>
            
            <td width="5%" style="vertical-align:middle;">
              <span class="heroCustomIcon material-icons" style="color: green; font-size: 40px;">
                groups
              </span>
            </td>
            <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="32%">
              <label class="text-white ml-2 HeroPrefrenceCustomStyle duoqueuestyle"><b>Hero Prefrence </b> &nbsp;<small style="font-size: 60%;">
                  (+{{HeroPrefrencePercentage}}%)</small>

                <span class="material-icons" data-toggle="tooltip" data-placement="top"
                  title="You can watch us play on your account, through a private live stream broadcast or VOD (if you want to watch it later)"
                  style="color: #2CA8FF; font-size: 15px;">
                  info
                </span></label>&nbsp;

            </td>
            <td style="padding: -6rem  !important;" class="text-left" width="10%">
              <ui-switch (change)="onHeroPrefrenceChange($event)" [checked]="IsHeroPreferencesChecked"
                class="HeroPrefrenceCustomStyle MarginLeftForSwitch" color="#2CA8FF" style="font-size: 30px;"
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
            </td>
           
          </tr>
          <tr>
            
            <td width="5%" style="vertical-align:middle;">
              <span class="material-icons" style="color: #FFB236; font-size: 40px;">
                bolt
              </span>
            </td>
            <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="32%">
              <label class="text-white ml-2 PriorityCustomStyle duoqueuestyle"><b>Priority Completion </b> &nbsp;<small
                  style="font-size: 60%;"> (+{{PriorityPercentage}}%)</small>

                <span class="material-icons" data-toggle="tooltip" data-placement="top"
                  title="If you purchase with this option, we will push your order to the top of the queue!"
                  style="color: #2CA8FF; font-size: 15px;">
                  info
                </span></label>&nbsp;
            </td>
            <td style="padding: -6rem  !important;" class="text-left" width="10%">
              <ui-switch (change)="onPriorityChange($event)" [checked]="IsPriorityCompletionChecked"
                class=" PriorityCustomStyle MarginLeftForSwitch" color="#2CA8FF" style="font-size: 30px;"
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
            </td>
           
          </tr>   <tr>
            
            <td width="5%" style="vertical-align:middle;">
              <span class="material-icons" style="color: #666666; font-size: 40px;">
                visibility_off
              </span>
            </td>
            <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="32%">
              <label class="text-white ml-2 PlayOfflineCustomStyle duoqueuestyle"><b>Play offline </b></label>

            </td>
            <td style="padding: -6rem  !important;" class="text-left" width="10%">
              <ui-switch (change)="onIsPlayOfflineCheckedChange($event)" [checked]="IsPlayOfflineChecked"
              class="PlayOfflineCustomStyle" color="#2CA8FF" style="font-size: 30px;" defaultBgColor="white" labelOn="Yes"
              labelOff="No"></ui-switch>
        </td>
           
          </tr>
        </table>

    </div>

      <div class="separator">
        <div class="line"></div>
        <p class="h3ReplaceHeading orderText">DISCOUNT</p>
        <div class="line"></div>
      </div>
      <div class="row mt-3">
        <input type="text" (keyup)="onKey($event)" [(ngModel)]="couponName" name="couponName" class="form-control"
          id="usr" style="text-align:center; margin-top: 20px; margin-left:70px; margin-right:80px;">
      </div>
      <p class="text-white " *ngIf="hideActualPrice">EZMMR45 code applied</p>
      <p class="text-white " *ngIf="hideActualPrice==false && couponName==''">Apply coupon
        code to avail discount</p>
      <p class="text-white " *ngIf="hideActualPrice==false && couponName!=''">Invalid coupon code </p>
      <div class="separator">
        <div class="line"></div>
        <p class="h3ReplaceHeading orderText">TOTAL</p>
        <div class="line"></div>
      </div>
        <p class="h3ReplaceHeading text-white"> <span [hidden]="!hideActualPrice"><del>{{actualPrice | number : "1.2-2"}}</del> </span> ${{TotalPrice | number : "1.2-2"}} </p>
        <!-- <p class="h3ReplaceHeading text-white">   Vat: ${{tax | number : "1.2-2"}} </p>
        <p class="h3ReplaceHeading text-white">   Total: ${{FullPrice | number : "1.2-2"}} </p> -->
        <!-- <div class="ml-5 mr-5">
          <app-Paypal  
          [ServiceName]="ServiceName"
          [StartFrom]="minValue"
          [ExpectedDays]="approximateDays"
          [Desired]="maxValue"
          [Streaming]="Streaming"
          [SpecificHero]="HeroPreferences"
          [PriorityCompletion]="PriorityCompletion"
          [PlayOffline]="PlayOffline"
          [ServicePrice]="TotalPrice"
          [SubTotal]="TotalPrice"
          [TaxPrice]="tax"
          [TotalPrice]="FullPrice"></app-Paypal>
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div> -->
        <button type="button" class="btn btn-primary mt-3 mb-2" style="width: 80%;" (click)='GoToCheckout()'>Checkout</button>
      <img class="mb-3" src="../../assets/paypalImages.png" width="70%"/>
    </div>
    
  </div>
 
</div>
