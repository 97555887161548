import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http:HttpClient) { }

  makePayment(stripeToken: any):Observable<any> {
    const url = "https://server.dota2mmrboost.com/checkout";
    return this.http.post<any>(url, {data:stripeToken})
  }
}
