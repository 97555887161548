<form #demo>
  <div class="row">




    <div class="col-md-7">
      <div class="container m-5">
        <div class="separator">
          <div class="line"></div>
          <p class="h3ReplaceHeading orderText">ORDER</p>
          <div class="line"></div>
        </div>
        <div class="row mb-5">
          <div class="col-md-3 text-center">
          </div>
          <div class="col-md-6 text-center">
            <label class="input-label">DOTA 2 BEHAVIOR SCORE</label>
            <div class="form-inline justify-content-center">
              
              <button class="InputMaxMinusButton" (click)="MinsValueButton()" >-</button>
              <input type="number" readonly style="background-color: white;" #MaxInput class="input-min" (keyup)="onKeyMax(MaxInput.value)" [value]="value" />
              <button class="InputMaxPlusButton" (click)="PlusValueButton()">+</button>
            </div>
            <!-- <div class="mt-3">
            <img class="ImageSizeSetting" *ngIf="value==2" src="./assets/icons/1.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==3" src="./assets/icons/2.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==4" src="./assets/icons/3.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==5" src="./assets/icons/4.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==6" src="./assets/icons/5.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==7" src="./assets/icons/6.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==8" src="./assets/icons/7.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==9" src="./assets/icons/8.jpg" />
            <img class="ImageSizeSetting" *ngIf="value==10" src="./assets/icons/9.jpg" />

          </div>-->
          </div>
          <div class="col-md-3 text-center">
          </div>
        </div>
        <!-- <ngx-slider 
      [(value)]="minValue"
      [(highValue)]="maxValue"
      [options]="options"
      (userChange)="onUserChange($event)"
    ></ngx-slider> -->
        <ngx-slider [(value)]="value" (userChange)="onUserChange($event)" [options]="options"></ngx-slider>
        <p class="text-center mt-5" style="color: #636264;">Displayed medals are approximate.</p>
      </div>
    </div>
    <div class="col-md-4 text-center ">

      <div class="container VerticleStyling">
        <h5 class="text-white pt-3">Your Order</h5>
        <div class="TopStyling">
          <h4 class="text-white">  {{value}} Dota 2 Behaviour Score </h4>
        </div>
        <div class="row m-1 mt-3">

          <table class="table table-borderless table-sm">
            <tr>
              <td width="5%" style="vertical-align:middle;">
                <span class="material-icons" style="color: #2CA8FF; font-size: 40px;">
                  people_alt
                </span>
          
              </td>
              <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
                <label class="text-white ml-2 duoqueuestyle duoCustomStyle"><b>Duo Queue </b> &nbsp;<small> (+{{DuoQueuePercentage}}%)</small></label>&nbsp;
                <span class="material-icons" data-toggle="tooltip" data-placement="top"
                  title="You can play on your own account, and group with us for your order!"
                  style="color: white; font-size: 15px;">
                  info
                </span>
              </td>
              <td style="padding: -6rem  !important;" class="text-left" width="10%">

                <ui-switch (change)="onDuoChange($event)" [checked]="IsDuoQueueChecked"class='MarginLeftForSwitch' color="#2CA8FF" style="font-size: 30px;"   defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>

            
              </td>

            </tr>
            <tr>
              <td width="5%" style="vertical-align:middle;">
                <span class="material-icons" style="color: #FF5555; font-size: 40px;">
                  movie
                </span>
      
          
              </td>
              <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
                <label class="text-white ml-2 duoqueuestyle StreamingCustomStyle"><b>Streaming </b> &nbsp;<small style="font-size: 60%;">
                  (+{{StreamingPercentage}}%)</small></label>&nbsp;
              <span class="material-icons" data-toggle="tooltip" data-placement="top"
                title="You can watch us play on your account, through a private live stream broadcast or VOD (if you want to watch it later)"
                style="color: #2CA8FF; font-size: 15px;">
                info
              </span>
              </td>
              <td style="padding: -6rem  !important;" class="text-left" width="10%">

                <ui-switch (change)="onStreamingChange($event)" [checked]="IsStreamingChecked"class='MarginLeftForSwitch' color="#2CA8FF" style="font-size: 30px;"   defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>

              </td>

            </tr>
            <tr>
              <td width="5%" style="vertical-align:middle;">
                <span class="material-icons" style="color: #FFB236; font-size: 40px;">
                  bolt
                </span>
                
              </td>
              <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
                <label class="text-white ml-2 duoqueuestyle PriorityCustomStyle"><b>Priority Completion </b> &nbsp;<small
                  style="font-size: 60%;"> (+{{PriorityPercentage}}%)</small></label>&nbsp;
              <span class="material-icons" data-toggle="tooltip" data-placement="top"
                title="If you purchase with this option, we will push your order to the top of the queue!"
                style="color: #2CA8FF; font-size: 15px;">
                info
              </span>
              </td>
              <td style="padding: -6rem  !important;" class="text-left" width="10%">

                <ui-switch (change)="onPriorityChange($event)" [checked]="IsPriorityCompletionChecked"class='MarginLeftForSwitch' color="#2CA8FF" style="font-size: 30px;" 
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
  
              </td>

            </tr>
            <tr>
              <td width="5%" style="vertical-align:middle;">
                <span class="material-icons" style="color: #666666; font-size: 40px;">
                  visibility_off
                </span>
  
              </td>
              <td style="padding: 0rem  !important;vertical-align:middle;" class="text-left" width="35%">
                <label class="text-white ml-2 duoqueuestyle PlayOfflineCustomStyle"><b>Play offline </b></label>

              </td>
              <td style="padding: -6rem  !important;" class="text-left" width="10%">

                <ui-switch (change)="onIsPlayOfflineCheckedChange($event)" [checked]="IsPlayOfflineChecked"class='MarginLeftForSwitch' color="#2CA8FF" style="font-size: 30px;" 
                defaultBgColor="white" labelOn="Yes" labelOff="No"></ui-switch>
    
              </td>

            </tr>
            </table>
      

        </div>
      
     
        <div class="separator">
          <div class="line"></div>
          <p class="h3ReplaceHeading orderText">DISCOUNT</p>
          <div class="line"></div>
        </div>
        <div class="row mt-3">
          <input type="text" (keyup)="onKey($event)" [(ngModel)]="couponName" name="couponName" class="form-control"
            id="usr" style="text-align:center; margin-top: 20px; margin-left:70px; margin-right:80px;">
        </div>
        <p class="text-white " *ngIf="hideActualPrice">ECHO10SLAM code applied</p>
        <p class="text-white " *ngIf="hideActualPrice==false && couponName==''">Apply coupon
          code to avail discount</p>
        <p class="text-white " *ngIf="hideActualPrice==false && couponName!=''">Invalid coupon code </p>
        <div class="separator">
          <div class="line"></div>
          <p class="h3ReplaceHeading orderText">TOTAL</p>
          <div class="line"></div>
        </div>
        <p class="h3ReplaceHeading text-white"> 
          <span [hidden]="!hideActualPrice"
            ><del>{{ actualPrice | number : "1.2-2" }} </del>&nbsp; </span
          > $ {{ TotalPrice | number : "1.2-2" }}
        </p>
        <!-- <div class="ml-5 mr-5">
          <app-Paypal  
          [ServiceName]="'Behaviour Score'"
          [StartFrom]="''"
          [ExpectedDays]="1"
          [Desired]="value"
          [Streaming]="Streaming"
          [SpecificHero]="''"
          [PriorityCompletion]="PriorityCompletion"
          [PlayOffline]="PlayOffline"
          [ServicePrice]="TotalPrice"
          [SubTotal]="TotalPrice"
          [TaxPrice]="tax"
          [DuoQueue]="DuoQueue"
          [TotalPrice]="FullPrice"></app-Paypal>
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div> -->
        <button type="button" class="btn btn-primary mt-3 mb-2" style="width: 80%;" (click)="GoToCheckout()">Checkout</button>

        <img class="mb-3" src="../../assets/paypalImages.png" width="70%"/>
      </div>


    </div>
  </div>
</form>