import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatIconModule} from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './slider/slider.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { BehaviourSliderComponent } from './behaviourslider/behaviourslider.component';
import { BattleCupComponent } from './battlecup/battlecup.component';
import { DotaCoachingComponent } from './dotacoaching/dotacoaching.component';
import { RankCalibrationComponent } from './rankcalibration.componet.ts/rankcalibration.component';
import { lowpriorityComponent } from './lowpriority/lowpriority.component';
import { accountbuyComponent } from './accountbuy/accountbuy.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalComponent } from './paypal/paypal.component';
import { HttpClientModule } from '@angular/common/http';
import { checkoutComponent } from './checkout/checkout.component';
import {MatRadioModule} from '@angular/material/radio';
import { dota2HeroTierBoostComponent } from './dota2HeroTierBoost/dota2HeroTierBoost.component';
import { normalwinsComponent } from './normalWins/normalwins.component';

@NgModule({
  declarations: [
    AppComponent,
    SliderComponent,
    lowpriorityComponent,
    BehaviourSliderComponent,
    BattleCupComponent,
    DotaCoachingComponent,
    RankCalibrationComponent,
    dota2HeroTierBoostComponent,
    accountbuyComponent,
    normalwinsComponent,
    PaypalComponent,
    checkoutComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    UiSwitchModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule ,
    AppRoutingModule,
    MatTooltipModule,
    AppRoutingModule,
    NgxSliderModule,
    NgxPayPalModule,
    MatRadioModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
