import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {

  constructor(
    public router:Router
  ){}
  IsDuoQueueChecked: boolean = false;
  IsStreamingChecked: boolean = false;
  IsPriorityCompletionChecked: boolean = false;
  IsHeroPreferencesChecked: boolean = false;
  IsPlayOfflineChecked: boolean = true;
  imagePath = '';
  minValue: number = 3000;
  maxValue: number = 6000;
  min100: number = 100;
  TotalPrice: number = 74.84;
  MinsMinValue: number = 0;
  couponName: string = 'EZMMR45';
  couponList: any;
  DuoQueuePercentage: number = 100;
  StreamingPercentage: number = 15;
  HeroPrefrencePercentage: number = 15;
  PriorityPercentage: number = 20;
  options: Options = {
    floor: 0,
    ceil: 12000,
    tickStep: 1000,
    showTicksValues: true,
    step: 1,

    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Current MMR:</b> ' + value;
        case LabelType.High:
          return '<b>Desired MMR:</b> ' + value;
        default:
          return '' + value;
      }
    },
  };

  actualPrice: number;
  approximateDays: number = 0;
  ServiceName: string;
  hideActualPrice: boolean = false;
  Streaming: string;
  HeroPreferences: string;
  PriorityCompletion: string;
  PlayOffline: string;
  tax: number;
  FullPrice: number;

  genericPriceFunction() {
    //this.TotalPrice = value * this.currrentPrice;
    this.actualPrice = this.TotalPrice;
    var currentCouponList = this.couponList.find(
      (x) => x.Name == this.couponName
    );
    if (currentCouponList != null) {
      if (currentCouponList != undefined) {
        this.TotalPrice =
          this.TotalPrice - this.TotalPrice * (currentCouponList.Value / 100);
        this.hideActualPrice = true;
      }
    } else {
      this.TotalPrice = this.actualPrice;
      this.hideActualPrice = false;
    }

    this.CheckIfDuoChecked();
    this.CheckIfStreamingChecked();
    this.CheckIfPriorityChecked();
    this.CheckIfHeroPrefrenceChecked();
    var a = parseFloat(this.TotalPrice.toString()).toFixed(2);
    this.TotalPrice = parseInt(a);
  }
  CheckIfDuoChecked() {
    if (this.IsDuoQueueChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.DuoQueuePercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.DuoQueuePercentage / 100);
    }
  }
  CheckIfStreamingChecked() {
    if (this.IsStreamingChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.StreamingPercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.StreamingPercentage / 100);
    }
  }

  CheckIfHeroPrefrenceChecked() {
    if (this.IsHeroPreferencesChecked == true) {
      this.TotalPrice =
        this.TotalPrice +
        this.TotalPrice * (this.HeroPrefrencePercentage / 100);
      this.actualPrice =
        this.actualPrice +
        this.actualPrice * (this.HeroPrefrencePercentage / 100);
    }
  }
  CheckIfPriorityChecked() {
    if (this.IsPriorityCompletionChecked == true) {
      this.TotalPrice =
        this.TotalPrice + this.TotalPrice * (this.PriorityPercentage / 100);
      this.actualPrice =
        this.actualPrice + this.actualPrice * (this.PriorityPercentage / 100);
    }
  }
  onDuoChange(event: any) {
    this.IsDuoQueueChecked = event;
    this.CheckTheMinMaxAndCalculate();
  }
  onStreamingChange(event: any) {
    this.IsStreamingChecked = event;
    this.CheckTheMinMaxAndCalculate();
  }
  onHeroPrefrenceChange(event: any) {
    this.IsHeroPreferencesChecked = event;
    this.CheckTheMinMaxAndCalculate();
  }

  onPriorityChange(event: any) {
    this.IsPriorityCompletionChecked = event;
    this.CheckTheMinMaxAndCalculate();
  }

  onIsPlayOfflineCheckedChange(event: any) {
    this.IsPlayOfflineChecked = event;
    this.CheckTheMinMaxAndCalculate();
  }

  ngOnInit() {
    this.couponList = new Array();
    this.actualPrice = this.TotalPrice;
    this.couponList = [{ Name: 'EZMMR45', Value: 40 }];
    this.CheckTheMinMaxAndCalculate();
  }

  onUserChange(event: any): void {
    // if(this.minValue < 100 && this.maxValue <100){

    // }
    //this.TotalPrice= this.CalculateFinalResult(1200,1800);
    this.CheckTheMinMaxAndCalculate();
  }

  MinsMinValueButton() {
    if (this.minValue > this.options.floor)
      this.minValue = this.minValue - this.min100;
    this.CheckTheMinMaxAndCalculate();
  }
  CheckTheMinMaxAndCalculate() {
    let minPrice = this.CheckMinMaxValue(this.minValue);
    let maxPrice = this.CheckMinMaxValue(this.maxValue);
    this.TotalPrice = maxPrice - minPrice;
    this.genericPriceFunction();
    this.tax = (this.TotalPrice / 100) * 4;
    this.FullPrice = this.TotalPrice + this.tax;
    this.CalculateApporximateDays();
    if (this.IsDuoQueueChecked == true) {
      this.ServiceName = 'Dota2 Duo Boost';
    } else {
      this.ServiceName = 'Dota2 Solo Boost';
    }
    if (this.IsHeroPreferencesChecked == true) {
      this.HeroPreferences = 'Yes';
    } else {
      this.HeroPreferences = 'No';
    }
    if (this.IsStreamingChecked == true) {
      this.Streaming = 'Yes';
    } else {
      this.Streaming = 'No';
    }
    if (this.IsPriorityCompletionChecked == true) {
      this.PriorityCompletion = 'Yes';
    } else {
      this.PriorityCompletion = 'No';
    }
    if (this.IsPlayOfflineChecked == true) {
      this.PlayOffline = 'Yes';
    } else {
      this.PlayOffline = 'No';
    }
    //this.calculateTotalPrice(minPrice,maxPrice);
  }
  calculateTotalPrice(MinPrice: number, MaxPrice: number) {
    if (MaxPrice > MinPrice) {
      this.TotalPrice = MaxPrice - MinPrice;
    }
    if (MinPrice > MaxPrice) {
      this.TotalPrice = MinPrice - MaxPrice;
    }
    if (MinPrice == MaxPrice) {
      this.TotalPrice = 0;
    }
    this.genericPriceFunction();
  }
  PlusMinValueButton() {
    if (this.minValue < this.options.ceil)
      this.minValue = this.minValue + this.min100;
    this.CheckTheMinMaxAndCalculate();
  }

  MinsMaxValueButton() {
    if (this.maxValue > this.options.floor)
      this.maxValue = this.maxValue - this.min100;
    this.CheckTheMinMaxAndCalculate();
  }
  PlusMaxValueButton() {
    if (this.maxValue <= this.options.ceil)
      this.maxValue = this.maxValue + this.min100;
    this.CheckTheMinMaxAndCalculate();
  }

  onKeyMin(value: number) {
    if (value < this.maxValue) {
      this.minValue = value;
      this.CheckTheMinMaxAndCalculate();
    }
  }
  onKeyMax(value: number) {
    if (value > this.minValue) {
      this.maxValue = value;
      this.CheckTheMinMaxAndCalculate();
    }
  }
  LiesBtw1: number = 58.41;
  LiesBtw2: number = 60.39;
  LiesBtw3: number = 64.3;
  LiesBtw4: number = 86.93;
  LiesBtw5: number = 146.52;
  LiesBtw6: number = 395.01;
  LiesBtw7: number = 1349.37;
  LiesBtw8: number = 2155.33;
  LiesBtw9: number = 2652.01;
  LiesBtw10: number = 3146.02;
  LiesBtw11: number = 3652.02;
  LiesBtw12: number = 4146.02;

  CheckMinMaxValue(MinMax: number) {
    if (MinMax <= 1000) {
      if (MinMax == 1000) {
        return this.LiesBtw1;
      } else {
        if (MinMax == 0) {
          return 0;
        } else {
          return (this.LiesBtw1 / 1000) * MinMax;
        }
      }
    }
    if (MinMax <= 2000 && MinMax > 1000) {
      if (MinMax == 2000) {
        return this.LiesBtw2 + this.LiesBtw1;
      } else {
        let a = MinMax - 1000;
        let b = (this.LiesBtw2 / 1000) * a;
        let c = this.LiesBtw1 + b;
        return c;
      }
    }
    if (MinMax <= 3000 && MinMax > 2000) {
      if (MinMax == 3000) {
        return this.LiesBtw2 + this.LiesBtw1 + this.LiesBtw3;
      } else {
        let a = MinMax - 2000;
        let b = (this.LiesBtw3 / 1000) * a;
        let c = this.LiesBtw1 + this.LiesBtw2 + b;
        return c;
      }
    }
    if (MinMax <= 4000 && MinMax > 3000) {
      if (MinMax == 4000) {
        return this.LiesBtw2 + this.LiesBtw1 + this.LiesBtw3 + this.LiesBtw4;
      } else {
        let a = MinMax - 3000;
        let b = (this.LiesBtw4 / 1000) * a;
        let c = this.LiesBtw1 + this.LiesBtw2 + this.LiesBtw3 + b;
        return c;
      }
    }
    if (MinMax <= 5000 && MinMax > 4000) {
      if (MinMax == 5000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5
        );
      } else {
        let a = MinMax - 4000;
        let b = (this.LiesBtw5 / 1000) * a;
        let c =
          this.LiesBtw1 + this.LiesBtw2 + this.LiesBtw3 + this.LiesBtw4 + b;
        return c;
      }
    }
    if (MinMax <= 6000 && MinMax > 5000) {
      if (MinMax == 6000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6
        );
      } else {
        let a = MinMax - 5000;
        let b = (this.LiesBtw6 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          b;
        return c;
      }
    }
    if (MinMax <= 7000 && MinMax > 6000) {
      if (MinMax == 7000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7
        );
      } else {
        let a = MinMax - 6000;
        let b = (this.LiesBtw7 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          b;
        return c;
      }
    }
    if (MinMax <= 8000 && MinMax > 7000) {
      if (MinMax == 8000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8
        );
      } else {
        let a = MinMax - 7000;
        let b = (this.LiesBtw8 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          b;
        return c;
      }
    }
    if (MinMax <= 9000 && MinMax > 8000) {
      if (MinMax == 9000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9
        );
      } else {
        let a = MinMax - 8000;
        let b = (this.LiesBtw9 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          b;
        return c;
      }
    }
    if (MinMax <= 10000 && MinMax > 9000) {
      if (MinMax == 10000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          this.LiesBtw10
        );
      } else {
        let a = MinMax - 9000;
        let b = (this.LiesBtw10 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          b;
        return c;
      }
    }
    if (MinMax <= 11000 && MinMax > 10000) {
      if (MinMax == 11000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          this.LiesBtw10 +
          this.LiesBtw11
        );
      } else {
        let a = MinMax - 10000;
        let b = (this.LiesBtw11 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          this.LiesBtw10 +
          b;
        return c;
      }
    }
    if (MinMax <= 12000 && MinMax > 11000) {
      if (MinMax == 12000) {
        return (
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          this.LiesBtw10 +
          this.LiesBtw11 +
          this.LiesBtw12
        );
      } else {
        let a = MinMax - 11000;
        let b = (this.LiesBtw12 / 1000) * a;
        let c =
          this.LiesBtw1 +
          this.LiesBtw2 +
          this.LiesBtw3 +
          this.LiesBtw4 +
          this.LiesBtw5 +
          this.LiesBtw6 +
          this.LiesBtw7 +
          this.LiesBtw8 +
          this.LiesBtw9 +
          this.LiesBtw10 +
          this.LiesBtw11 +
          b;
        return c;
      }
    }
  }
  onKey(event: any) {
    this.couponName = event.target.value;
    //this.genericPriceFunction();
    this.CheckTheMinMaxAndCalculate();
  }
  // CalculateFinalResult(min:any,max:any){
  //   const stepSize = 1000;
  //   const absoluteMin =0;
  //   const absoluteMax = 10000;
  //   const totalSteps = absoluteMax/stepSize;
  //   let sum =0;
  //   let minFound = false;
  //   let maxFound = false;
  //   for(var multiplier=1;multiplier<=totalSteps;multiplier++){
  //      let end = multiplier*stepSize;
  //      let start = end-stepSize;
  //      if(min >= start && max < end){
  //          sum = (max-min) * multiplier;
  //          break;
  //      }
  //      if(min>end){
  //          continue;
  //      }
  //      if(min>=start && min <=end){
  //          sum += (end-min)*multiplier;
  //          console.log('min found', sum)
  //          continue;
  //      }
  //      if(max>=start && max <=end){
  //          sum+= (end-max)*multiplier;
  //          break;
  //      }
  //      sum+= (end*multiplier)
  //   }
  //   return sum;
  // }

  CalculateApporximateDays() {
    var totalValue = this.maxValue - this.minValue;

    var days = Math.floor((totalValue / 1000) * 7);

    if (days == 0) {
      this.approximateDays = 7;
    } else if (this.IsPriorityCompletionChecked == true) {
      this.approximateDays = Math.floor(days - (days / 100) * 10);
    } else {
      this.approximateDays = days;
    }
  }
  GoToCheckout(){
    localStorage.setItem('ServiceName',this.ServiceName);
    localStorage.setItem('ServicePrice',this.TotalPrice.toString());
    localStorage.setItem('SubTotal',this.TotalPrice.toString());
    localStorage.setItem('TaxPrice',this.tax.toString());
    localStorage.setItem('TotalPrice',this.FullPrice.toString());
    localStorage.setItem('DuoQueue',this.IsDuoQueueChecked == true?'Yes':'No');
    localStorage.setItem('StartFrom', this.minValue.toString());
    localStorage.setItem('Desired',this.maxValue.toString());
    localStorage.setItem('Streaming',this.IsStreamingChecked == true?'Yes':'No');
    localStorage.setItem('SpecificHero',this.IsHeroPreferencesChecked == true?'Yes':'No');
    localStorage.setItem('PriorityCompletion',this.IsPriorityCompletionChecked == true?'Yes':'No');
    localStorage.setItem('PlayOffline',this.IsPlayOfflineChecked == true?'Yes':'No');
    localStorage.setItem('ExpectedDays',this.approximateDays.toString());

     window.open("https://dota2mmrboost.com/checkout", "_blank");

    //this.router.navigateByUrl('/Checkout');
  }
}
