import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BattleCupComponent } from './battlecup/battlecup.component';
import { BehaviourSliderComponent } from './behaviourslider/behaviourslider.component';
import { DotaCoachingComponent } from './dotacoaching/dotacoaching.component';
import { lowpriorityComponent } from './lowpriority/lowpriority.component';
import { RankCalibrationComponent } from './rankcalibration.componet.ts/rankcalibration.component';
import { SliderComponent } from './slider/slider.component';
import { accountbuyComponent } from './accountbuy/accountbuy.component';
import { checkoutComponent } from './checkout/checkout.component';
import { dota2HeroTierBoostComponent } from './dota2HeroTierBoost/dota2HeroTierBoost.component';
import { normalwinsComponent } from './normalWins/normalwins.component';

const routes: Routes = [
  { path: 'Slider', component: SliderComponent },
  { path: '', redirectTo: '/Slider', pathMatch: 'full'},
  { path: 'lowpriority', component: lowpriorityComponent },
  { path: '', redirectTo: '/Slider1', pathMatch: 'full'},
  { path: 'BehaviourSlider', component: BehaviourSliderComponent },
  { path: '', redirectTo: '/BehaviourSlider', pathMatch: 'full'},
  { path: 'BattleCupSlider', component: BattleCupComponent },
  { path: '', redirectTo: '/BattleCupSlider', pathMatch: 'full'},
  { path: 'DotaCoachingSlider', component: DotaCoachingComponent },
  { path: '', redirectTo: '/DotaCoachingSlider', pathMatch: 'full'},
  { path: 'Dota2HeroTierBoost', component: dota2HeroTierBoostComponent},
  { path: '', redirectTo: '/Dota2HeroTierBoost', pathMatch: 'full'},
  { path: 'RankCalibration', component: RankCalibrationComponent},
  { path: '', redirectTo: '/RankCalibration', pathMatch: 'full'},
  { path: 'AccountBuy', component: accountbuyComponent},
  { path: '', redirectTo: '/AccountBuy', pathMatch: 'full'},
  { path: 'NormalWins', component: normalwinsComponent},
  { path: '', redirectTo: '/NormalWins', pathMatch: 'full'},
  { path: 'Checkout', component: checkoutComponent},
  { path: '', redirectTo: '/Checkout', pathMatch: 'full'}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
