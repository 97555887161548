<form #demo>
  <div class="separator">
    <div class="line"></div>
    <p class="h3ReplaceHeading orderText">ORDER</p>
    <div class="line"></div>
  </div>
  <div class="row m-2">
    <div class="col-md-6 text-center">
      <img
      class="ImageSizeSetting"
      src="../../assets/dota2 jagguarnaut.webp"
    />
    </div>
    <div class="col-md-6 p-5 text-center">
      <h1 class="text-white" style="font-size: 35px;font-weight: 700;">DOTA 2 NORMAL WINS</h1>
      <p class="text-white" style="font-size: 16px;">
      This is for only Dota 2 normal wins.
      Each game will take 1-40 mins minimum.
      If priority is selected, order will start within 5 minutes.
      </p>
     <p class="text-white" style="font-size: 18px;">Select number of games</p>
     <input
     type="number"
     #MaxInput
     (keyup)="NormalWinsCalculate(MaxInput.value)"
     class="input-min"
     [(value)]="SelectedGames"
   />
      <p *ngIf="TotalPrice != 0" class="text-white m-4" style="font-size: 26px;"> 
        ${{TotalPrice | number : '1.2-2'}}</p>
        <div *ngIf="TotalPrice != 0">
         
          <!-- <app-Paypal  
          [ServiceName]="'Account Buy'"
          [StartFrom]="''"
          [ExpectedDays]="0"
          [Desired]="SelectedAccount"
          [Streaming]="''"
          [SpecificHero]="''"
          [PriorityCompletion]="''"
          [PlayOffline]="''"
          [ServicePrice]="TotalPrice"
          [SubTotal]="TotalPrice"
          [TaxPrice]="0"
          [DuoQueue]="''"
          [TotalPrice]="TotalPrice"></app-Paypal>
          <img class="mb-3" src="../../assets/paypalImages.png" width="70%" /> -->
          <button width="70%" type="button" class="btn btn-primary mt-3 mb-2" style="width: 80%;" (click)="GoToCheckout()">Checkout</button>
        </div>

      

    </div>
  </div>
</form>
